/**
 * Zoom Client Singleton
 */
export class ZoomClient {
  static _instance: null;
  hostname = "";
  users = [];

  constructor() {}

  static getInstance(): ZoomClient {
    if (ZoomClient._instance == null) {
      ZoomClient._instance = new ZoomClient() as any;
    }
    return ZoomClient._instance as any;
  }

  init(hostname: string) {
    this.hostname = hostname;
    return this;
  }

  async post(url = "", data = {}) {
    // Default options are marked with *
    fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Access-Control-Allow-Origin': '*'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.log("An error occured ", error));
  }

  async get(path: string, params?: Object) {
    return fetch(this.hostname + path, params);
  }

  // FIXME: This is grade A hot trash but if it works for now, coooool.
  async sendZoomCommand(
    cmd: string,
    arg1?: any,
    arg2?: any,
    arg3?: any,
    arg4?: any
  ) {
    return await this.post(this.hostname + "/send-async", {
      cmd: cmd,
      arg1: arg1,
      arg2: arg2,
      arg3: arg3,
      arg4: arg4,
    });
  }
}

export enum ZoomCommands {
  MUTE_AUDIO = "muteAudio",
  UNMUTE_AUDIO = "unMuteAudio",
  SPOTLIGHT_VIDEO = "spotlightVideo",
  START_CLOUD_RECORDING = "startCloudRecording",
}
