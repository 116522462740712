import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  SignUp,
  Login,
  EmailLinkAuth,
  EmailMeALink,
} from "../../Auth/components";
import { Routes } from "../../../config/constants";

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path={Routes.LOGIN} component={Login} />
      {/* <Route path={Routes.SIGNUP} component={SignUp} /> */}
      <Route path={Routes.VERIFY_EMAIL} component={EmailLinkAuth} />
      <Route path={Routes.EMAIL_ME_A_LINK} component={EmailMeALink} />
      <Redirect to={Routes.LOGIN} from="/" />
    </Switch>
  );
};
