import React, { useState, useEffect } from "react";
import { getSiteBase } from "@afterword/core/utils";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import { DataGrid, GridColDef, GridToolbar } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Routes } from "../../../config/constants";

const Humans = () => {
  const history = useHistory();
  const [humansList, setHumansList] = useState([] as any);
  const [humanDataRows, setHumanDataRows] = useState([] as any);
  const [quickSearchFilter, setQuickSearchFilter] = useState([] as any);

  const db = firebase.firestore();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Edit",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          history.push(Routes.ADMIN_HUMANS + "/" + params.value);
        };

        return (
          <button onClick={onClick} className="btn btn-createHuman">
            Edit
          </button>
        );
      },
      width: 130,
    },
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    {
      field: "pageSlug",
      headerName: "Slug",
      width: 150,
    },
    {
      field: "serviceDate",
      headerName: "Memorial date",
      type: "date",
      width: 180,
    },
    {
      field: "isPublished",
      headerName: "Published?",
      type: "boolean",
      width: 150,
    },

    {
      field: "hasVideoask",
      headerName: "Videoask?",
      type: "boolean",
      width: 150,
    },
    {
      field: "hasRecording",
      headerName: "Recording?",
      type: "boolean",
      width: 150,
    },
    {
      field: "View Link",
      headerName: "View",
      renderCell: (params) => {
        return (
          <a
            href={`${getSiteBase()}/stories/${params.getValue(
              params.id,
              "pageSlug"
            )}`}
            target="_blank"
          >
            View
          </a>
        );
      },
      width: 130,
    },
  ];

  const timestampToDatetime = (ts: firebase.firestore.Timestamp) => {
    return !ts ? "" : new Date(ts.seconds * 1000).toISOString().substr(0, 16);
  };

  useEffect(() => {
    const fetchHumans = async () => {
      const humansCollection = await db
        .collection("humans")
        .orderBy("firstName")
        .get();
      setHumansList(humansCollection.docs);
    };
    fetchHumans();
  }, []);

  useEffect(() => {
    let human_data_rows = [
      humansList.map((doc: any) => {
        let data = doc.data();
        if (!data.isDraft) {
          return {
            id: doc.id,
            firstName: data.firstName,
            lastName: data.lastName,
            serviceDate: timestampToDatetime(data.memorialDatetime),
            isPublished: data.isPublished,
            pageSlug: data.pageSlug,
            hasVideoask: data.storiesQuestionUrl && data.storiesAnswerUrl,
            hasRecording: data.recordingBlobs && data.recordingBlobs.length > 0,
          };
        } else {
          return null;
        }
      }),
    ];
    // filter out empty docs
    var human_data_rows_cleaned = human_data_rows[0].filter(function (el: any) {
      return el != null;
    });
    setHumanDataRows(human_data_rows_cleaned);
  }, [humansList]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <div
        style={{
          maxWidth: "400px",
          margin: "10px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search by pageSlug"
          variant="filled"
          className="block"
          autoFocus
          onChange={(event) => {
            // setFirstName(event.target.value)
            console.log(event.target.value);
            setQuickSearchFilter([
              {
                columnField: "pageSlug",
                operatorValue: "contains",
                value: event.target.value,
              },
            ]);
          }}
          // fullWidth={true}
          style={{ backgroundColor: "white" }}
        />

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const docRef = db.collection("humans").doc();
            console.log(docRef);
            // Set created date
            const createdDateTimestamp = firebase.firestore.Timestamp.fromDate(
              new Date()
            );
            const createdData = {
              createdDate: createdDateTimestamp,
              customColumnSpacing: "1fr 3fr",
              variant: "default",
            };
            docRef.set(createdData).then(() => {
              history.push(Routes.ADMIN_HUMANS + "/" + docRef.id);
            });
          }}
          className="btn btn-createHuman"
        >
          Create New
        </Button>
      </div>
      {/* <ul>
        {humansList.map((doc: any) => {
          let data = doc.data();
          if (!data.isDraft) {
            return (
              <li key={doc.id}>
                <button
                  onClick={() => {
                    history.push(Routes.ADMIN_HUMANS + "/" + doc.id);
                  }}
                >
                  {data.pageSlug} — {data.isPublished ? "published" : "hidden"}{" "}
                  —
                </button>
                <a
                  className="view-link"
                  href={`${getSiteBase()}/stories/${data.pageSlug}`}
                  target="_blank"
                >
                  view
                </a>
              </li>
            );
          }
        })}
      </ul> */}
      <div
        style={{
          height: "calc(100vh - 90px)",
          width: "90%",
          maxWidth: "1400px",
          margin: "0 auto",
        }}
      >
        <DataGrid
          rows={humanDataRows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100, 200, 500]}
          components={{
            Toolbar: GridToolbar,
          }}
          filterModel={{
            items: quickSearchFilter,
          }}
        />
      </div>
    </div>
  );
};

export default Humans;
