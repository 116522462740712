import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import uuid from "react-uuid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Input } from "@material-ui/core";

import { HumanTimeline } from "../components/humanTimeline";

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export enum SaveStatus {
  NEW = "new",
  LOADING = "loading",
  LOADED = "loaded",
  SAVING = "saving",
  SAVED = "saved",
  SAVING_ERROR = "saving_error",
}
interface RecordingBlob {
  url: string;
  poster: string;
  title: string;
}

const HumanPostService = ({ humanId }: any) => {
  const history = useHistory();
  const [saveStatus, setSaveStatus] = useState(SaveStatus.NEW);
  const [imageAsFile, setImageAsFile] = useState("");
  const [recordingBlobs, setRecordingBlobs] = useState(
    [] as Array<RecordingBlob>
  );
  const storageRef = firebase.storage().ref();

  useEffect(() => {
    fetchAndSetHuman();
  }, []);

  const fetchAndSetHuman = async () => {
    setSaveStatus(SaveStatus.LOADING);
    const humanData = await db.collection("humans").doc(String(humanId)).get();
    if (!humanData.exists) {
      console.log("No such document!");
      return;
    } else {
      let human: any = humanData.data();

      // Objects
      setRecordingBlobs(human.recordingBlobs || []);

      setSaveStatus(SaveStatus.LOADED);
    }
  };

  const getHumanFromState = () => {
    return {
      recordingBlobs: recordingBlobs,
    };
  };

  const handleImageUpload = async (event: any) => {
    event.preventDefault();
    const image = event.target.files[0];
    console.log(image);
    setImageAsFile(image);
    return await saveImageToFirebase(image);
  };

  const saveImageToFirebase = async (image: any) => {
    var newMetadata = {
      cacheControl: "public,max-age=4000",
    };
    const fileRef = storageRef.child(`humans/${humanId}/` + uuid());
    await fileRef.put(image, newMetadata);
    let newDownloadURL = await fileRef.getDownloadURL();
    console.log(newDownloadURL);
    return String(newDownloadURL);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    var newHumanRef = db.collection("humans").doc(String(humanId));
    console.log("getHumanFromState()", getHumanFromState());

    setSaveStatus(SaveStatus.SAVING);
    newHumanRef
      .update(getHumanFromState())
      .then(() => {
        setSaveStatus(SaveStatus.SAVED);
      })
      .catch((err) => {
        console.log("error", "=>", err);
        setSaveStatus(SaveStatus.SAVING_ERROR);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={9}>
            <Card style={{ padding: "40px", marginBottom: "40px" }}>
              <CardHeader title="Recordings" style={{ padding: "0px" }} />
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                {(recordingBlobs || []).map((blob, idx) => {
                  return (
                    <Grid item xs={12} sm container spacing={4}>
                      <Grid item sm={4} className="humanFormItem">
                        <TextField
                          id="outlined-basic"
                          label="Recording URL"
                          variant="filled"
                          type="url"
                          value={blob.url}
                          onChange={(event) => {
                            let newBlobs = recordingBlobs.concat([]);
                            newBlobs[idx].url = event.target.value;
                            setRecordingBlobs(newBlobs);
                          }}
                          fullWidth={true}
                          style={{ backgroundColor: "white" }}
                        />
                      </Grid>
                      <Grid item sm={4} className="humanFormItem">
                        <TextField
                          id="outlined-basic"
                          label="Recording title"
                          variant="filled"
                          value={blob.title}
                          onChange={(event) => {
                            let newBlobs = recordingBlobs.concat([]);
                            newBlobs[idx].title = event.target.value;
                            setRecordingBlobs(newBlobs);
                          }}
                          fullWidth={true}
                          style={{ backgroundColor: "white" }}
                        />
                      </Grid>
                      <Grid item sm={6} className="humanFormItem">
                        <label>Recording Poster:</label>
                        <input
                          type="file"
                          // className="block"
                          onChange={(event) => {
                            let downloadUrl = handleImageUpload(event);
                            downloadUrl.then((result) => {
                              let newBlobs = recordingBlobs.concat([]);
                              newBlobs[idx].poster = String(result);
                              setRecordingBlobs(newBlobs);
                            });
                          }}
                        />
                        {blob.poster && (
                          <a href={blob.poster} target="_blank">
                            <img
                              src={blob.poster}
                              style={{ maxWidth: "200px" }}
                            />
                          </a>
                        )}
                      </Grid>
                      <Grid item sm={12} className="humanFormItem">
                        <button
                          type="button"
                          className="btn btn-green text-xs"
                          onClick={() => {
                            let shouldDelete = window.confirm("Are you sure?");
                            if (!shouldDelete) {
                              return;
                            }
                            let newBlob = recordingBlobs.concat([]);
                            newBlob.splice(idx, 1);
                            setRecordingBlobs(newBlob);
                          }}
                        >
                          remove recording
                        </button>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} className="humanFormItem">
                  <button
                    type="button"
                    className="btn btn-grey text-xs"
                    onClick={() => {
                      let newBlob = recordingBlobs.concat({} as RecordingBlob);
                      setRecordingBlobs(newBlob);
                    }}
                  >
                    add recording
                  </button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <div className="save-container" style={{ textAlign: "center" }}>
              <Button color="primary" variant="contained" type="submit">
                Save
              </Button>
              <div className="text-gray-500 text-md">{saveStatus}</div>
              {saveStatus === SaveStatus.SAVING_ERROR && (
                <div className="text-red-500 text-lg">Error: check console</div>
              )}
            </div>
            <HumanTimeline />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default HumanPostService;
