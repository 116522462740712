import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./AuthProvider";
import firebase from "./firebase";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

type ContextProps = {
  humanModel: any;
  setHumanModel: any;
  saveStatus: string;
  saveToFirestore: any;
  updateFirestore: any;
  isLoaded: boolean;
};

export enum SaveStatus {
  NEW = "new",
  CHANGED = "CHANGED",
  LOADING = "loading",
  LOADED = "loaded",
  SAVING = "saving",
  SAVED = "saved",
  SAVING_ERROR = "saving_error",
}

export const SaveIndicator = ({
  onClickSave,
  onClickView,
  textOverride,
  hideStatus,
  saveStatus,
}: any) => {
  return (
    <Grid
      container
      justify="space-evenly"
      alignItems="center"
      direction="row"
      style={{ textAlign: "center" }}
    >
      {onClickView && (
        <Button color="secondary" variant="contained" onClick={onClickView}>
          View
        </Button>
      )}
      <div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={onClickSave}
        >
          {textOverride || "Save"}
        </Button>
        {!hideStatus && (
          <div style={{ minWidth: "24px", display: "inline-block" }}>
            {[SaveStatus.SAVED, SaveStatus.LOADED].indexOf(saveStatus) > -1 && (
              <CheckCircleOutlinedIcon />
            )}
            {saveStatus === SaveStatus.SAVING_ERROR && (
              <div className="text-red-500 text-lg">Error: check console</div>
            )}
          </div>
        )}
      </div>
    </Grid>
  );
};

export const HumanContext = React.createContext<Partial<ContextProps>>({});

export const HumanProvider = ({ humanId, children }: any) => {
  const [humanModel, setHumanModel] = useState(null as any);
  const [saveStatus, setSaveStatus] = useState(SaveStatus.NEW);
  const [isLoaded, setIsLoaded] = useState(false);
  const { user } = useContext(AuthContext);

  const db = firebase.firestore();

  useEffect(() => {
    fetchAndSetHuman();
  }, []);

  const fetchAndSetHuman = async () => {
    setSaveStatus(SaveStatus.LOADING);
    const humanData = await db.collection("humans").doc(String(humanId)).get();
    if (!humanData.exists) {
      console.log("No such document!");
      return;
    } else {
      let human: any = humanData.data();
      // TODO: Add testing here to confirm ACLs
      if (human.connectedUsers) {
        console.log(human.connectedUsers);
      }
      if (!human.signedContractsTerms) {
        human.signedContractsTerms = {};
      }
      setHumanModel(human);
      setSaveStatus(SaveStatus.LOADED);
    }
    setIsLoaded(true);
  };

  /**
   * Returns an object of the Human model, ready for writing to Firebase.
   * Most of the attributes stored in State are ready to push to Firebase;
   * however, certain field types, e.g. dates, need some massaging before
   * pushing to Firebase.
   * @returns Object
   */
  const getHumanFromState = () => {
    // transform date type variables (human bio)
    let birthDateTimestamp = humanModel.birthDate
      ? firebase.firestore.Timestamp.fromDate(new Date(humanModel.birthDate))
      : null;
    let deathDateTimestamp = humanModel.deathDate
      ? firebase.firestore.Timestamp.fromDate(new Date(humanModel.deathDate))
      : null;

    // transform date type variables (human memorial)
    let memorialDateTimestamp = humanModel.memorialDateTime
      ? firebase.firestore.Timestamp.fromDate(
          new Date(humanModel.memorialDateTime)
        )
      : null;
    let formattedDatetimeTimestamp = humanModel.formattedDatetime
      ? firebase.firestore.Timestamp.fromDate(
          new Date(humanModel.formattedDatetime)
        )
      : null;

    let lastModifiedDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date()
    );

    return {
      ...humanModel,
      birthDate: birthDateTimestamp,
      deathDate: deathDateTimestamp,
      memorialDatetime: memorialDateTimestamp,
      memorialDatetimeTz: formattedDatetimeTimestamp,
      lastModifiedDate: lastModifiedDateTimestamp,
    };
  };

  const updateFirestore = async (updatedModel: any) => {
    console.log(updatedModel);
    var newHumanRef = db.collection("humans").doc(String(humanId));

    setSaveStatus(SaveStatus.SAVING);
    return newHumanRef
      .update(updatedModel)
      .then(() => {
        return setSaveStatus(SaveStatus.SAVED);
      })
      .catch((err) => {
        console.log("error", "=>", err);
        setSaveStatus(SaveStatus.SAVING_ERROR);
      });
  };

  const saveToFirestore = async (event: any) => {
    event.preventDefault();
    return updateFirestore(getHumanFromState());
  };

  return (
    <HumanContext.Provider
      value={{
        humanModel,
        setHumanModel,
        saveStatus,
        saveToFirestore,
        updateFirestore,
        isLoaded,
      }}
    >
      {children}
    </HumanContext.Provider>
  );
};
