import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import { ObsContext, ObsProvider } from "../../../OBSProvider";
import {
  ProgramCommandType,
  Program,
  ZoomCommandSettings,
  OBSCommandSettings,
  ProgramCommand,
} from "./models";
import { ZoomClient, ZoomCommands } from "./ZoomClient";

const ProgramPage = () => {
  const history = useHistory();
  const [userName, setUserName] = useState("");

  /**
   * Array of Zoom User objects.
   */
  const [eventAudience, setEventAudience] = useState([]);

  /**
   * OBS Websocket hostname.
   */
  const [obsUrl, setObsUrl] = useState("");

  /**
   * Zoom webserver hostname.
   */
  const [zoomUrl, setZoomUrl] = useState("");

  /**
   * OBS helper variables and methods from OBS Provider.
   * TODO: Move this to the ObsClient singleton.
   */
  const {
    obs,
    isConnected,
    connect,
    disconnect,
    scenes,
    currentScene,
    sources,
    setHost,
  } = useContext(ObsContext);

  const db = firebase.firestore();

  /**
   * The program primary key.
   * */
  const [programId, setProgramId] = useState("");

  /**
   * The current step of the program as an array index.
   * An unstarted program is represented by -1.
   * */
  const [programStep, setProgramStep] = useState(-1);

  /**
   * Whether the inputs are disabled.
   */
  const [isEditMode, setIsEditMode] = useState(true);

  /**
   * Toggles manual controls of OBS & Zoom.
   */
  const [isManualMode, setIsManuelMode] = useState(true);

  /**
   * An encoded image showing a preview of the OBS feed.
   */
  const [sceneImages, setSceneImages] = useState(Object);
  const [previewPrev, setPreviewPrev] = useState("");
  const [previewCurr, setPreviewCurr] = useState("");
  const [previewNext, setPreviewNext] = useState("");
  // const [previewImages, setPreviewImages] = useState({ prev: "", current: "", next: "" });

  /**
   * The model for the program being viewed.
   */
  const [loadedProgram, setLoadedProgram] = useState({} as Program);

  /**
   * Whether to show program steps.
   */
  // TODO make this a func and handle logic with programStep > -1
  const [shouldShowSteps, setShouldShowSteps] = useState(false);

  let zoom = ZoomClient.getInstance();

  let { pId } = useParams();

  /**
   * Creates a component for each Program Command.
   * TODO: Replace the table row once we have updated designs.
   * @param cmd
   * @param idx
   * @param stepIdx
   * @param loadedProgram
   * @param setLoadedProgram
   */
  const ProgramCommandComponent = (
    cmd: ProgramCommand,
    idx: number,
    stepIdx: number,
    loadedProgram: Program,
    setLoadedProgram: any
  ) => {
    return (
      <>
        {shouldShowSteps && (
          <tr key={stepIdx + " " + idx}>
            <td className="px-3 py-2 whitespace-nowrap">
              <label className="block">
                <span className="text-gray-700 text-sm">Command Type</span>
                <select
                  value={cmd.type}
                  disabled={!isEditMode}
                  className="block"
                  onChange={(event) => {
                    let newProgram = { ...loadedProgram };
                    newProgram.steps[stepIdx].commands[idx].type = event?.target
                      .value as ProgramCommandType;
                    setLoadedProgram({ ...newProgram });
                  }}
                >
                  <option
                    key={ProgramCommandType.NONE}
                    value={ProgramCommandType.NONE}
                  >
                    --
                  </option>
                  <option
                    key={ProgramCommandType.OBS}
                    value={ProgramCommandType.OBS}
                  >
                    OBS
                  </option>
                  <option
                    key={ProgramCommandType.ZOOM}
                    value={ProgramCommandType.ZOOM}
                  >
                    Zoom
                  </option>
                </select>
              </label>
            </td>

            <td className="px-3 py-2 whitespace-nowrap">
              {cmd.type == ProgramCommandType.OBS && (
                <div className="grid grid-cols-4">
                  <label className="block">
                    <span className="text-gray-700 text-sm">Scene Name</span>
                    <select
                      value={cmd.settings.sceneName}
                      disabled={!isEditMode}
                      className="block"
                      onChange={(event) => {
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.sceneName = event?.target.value;
                        setLoadedProgram({ ...newProgram });
                      }}
                    >
                      {/* Put an empty scene here */}
                      {scenes &&
                        scenes.map((scene) => {
                          return (
                            <option key={scene.name} value={scene.name}>
                              {scene.name}
                            </option>
                          );
                        })}
                    </select>
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-sm">Volume</span>
                    <input
                      type="range"
                      className="block"
                      value={cmd.settings.volumeLevel}
                      disabled={!isEditMode}
                      placeholder="volume"
                      onChange={(event) => {
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.volumeLevel = Number(event?.target.value);
                        setLoadedProgram({ ...newProgram });
                      }}
                    ></input>
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-sm block">&nbsp;</span>
                    <input
                      type="checkbox"
                      checked={cmd.settings.shouldMuteMic}
                      disabled={!isEditMode}
                      onChange={(_) => {
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.shouldMuteMic = !cmd.settings.shouldMuteMic;
                        setLoadedProgram({ ...newProgram });
                      }}
                    />
                    <span className="text-gray-700 text-sm ml-2">Mute Mic</span>
                  </label>
                </div>
              )}

              {cmd.type == ProgramCommandType.ZOOM && (
                <div className="grid grid-cols-4">
                  <label className="block">
                    <span className="text-gray-700 text-sm">Speaker Name</span>
                    <select
                      className="block"
                      value={cmd.settings.speakerId}
                      disabled={!isEditMode}
                      onChange={(event) => {
                        let newValue = event?.target.value;
                        // console.log(newValue);
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.speakerId = Number(newValue);
                        setLoadedProgram({ ...newProgram });
                      }}
                    >
                      <option value="">--</option>
                      {eventAudience.map((user: any) => {
                        return (
                          <option value={user.userID}>{user.userName}</option>
                        );
                      })}
                    </select>
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-sm block">&nbsp;</span>
                    <input
                      type="checkbox"
                      checked={cmd.settings.shouldSpotlight}
                      disabled={!isEditMode}
                      onChange={(_) => {
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.shouldSpotlight =
                          !cmd.settings.shouldSpotlight;
                        setLoadedProgram({ ...newProgram });
                      }}
                    />
                    <span className="text-gray-700 text-sm ml-2">
                      Spotlight Speaker
                    </span>
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-sm block">&nbsp;</span>
                    <input
                      type="checkbox"
                      checked={cmd.settings.shouldMuteOthers}
                      disabled={!isEditMode}
                      onChange={(_) => {
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.shouldMuteOthers =
                          !cmd.settings.shouldMuteOthers;
                        setLoadedProgram({ ...newProgram });
                      }}
                    />
                    <span className="text-gray-700 text-sm ml-2">
                      Should Mute Others
                    </span>
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-sm block">&nbsp;</span>
                    <input
                      type="checkbox"
                      checked={cmd.settings.shouldStartRecording}
                      disabled={!isEditMode}
                      onChange={(_) => {
                        let newProgram = { ...loadedProgram };
                        newProgram.steps[stepIdx].commands[
                          idx
                        ].settings.shouldStartRecording =
                          !cmd.settings.shouldStartRecording;
                        setLoadedProgram({ ...newProgram });
                      }}
                    />
                    <span className="text-gray-700 text-sm ml-2">
                      Start Recording
                    </span>
                  </label>
                </div>
              )}
            </td>
            <td className="px-3 py-2 whitespace-nowrap text-right text-xs">
              <button
                className="btn"
                onClick={(event) => {
                  let shouldDelete = window.confirm(
                    "Are you sure you want to remove this command?"
                  );
                  if (!shouldDelete) {
                    return;
                  }
                  let newProgram = { ...loadedProgram };
                  newProgram.steps[stepIdx].commands.splice(idx, 1);
                  setLoadedProgram({ ...newProgram });
                }}
              >
                Remove Cmd
              </button>
            </td>
          </tr>
        )}
      </>
    );
  };

  useEffect(() => {
    // Example Order:
    // Get User Id from Firebase
    // Get Program Id from URL
    // Does User have access to this program?
    // Load Program in State

    // Initialize Zoom
    let search = new URLSearchParams(window.location.search);
    let zurl = search.get("zurl") || "http://localhost:10026";
    setZoomUrl(zurl);
    zoom.init(zurl);

    // Initialize OBS
    let defaultObs =
      process.env.NODE_ENV === "development"
        ? "localhost:4444"
        : "afterword.ngrok.io";
    let obsUrl = search.get("obsUrl") || search.get("o") || defaultObs;
    setObsUrl(obsUrl);
    setHost(obsUrl);

    setShouldShowSteps(!!search.get("ctrl"));
    if (pId) {
      setProgramId(pId);
      fetchProgram(pId);
    } else {
      let draftRef = db.collection("programs").doc();
      let tempId = draftRef.id;
      let newProgram = {
        id: tempId,
        name: "New Program",
        programStep: -1,
        steps: [],
      };
      draftRef.set(newProgram).then(() => {
        history.push("/program/" + tempId);
        setProgramId(tempId);
        fetchProgram(tempId);
      });
    }
  }, []);

  // OBS
  useEffect(() => {
    if (!isConnected && programStep > 0) {
      console.log("oh crap we got disconnected!");
      setTimeout(() => {
        reconnect();
      }, 1000);
    }
    if (!isConnected && programStep > 0 && obsUrl) {
      console.log("should connect");
      connect();
    }
  }, [isConnected, obsUrl]);

  async function setProgramStepsFromScenes() {
    if (loadedProgram.steps) {
      let confirm = window.confirm("This program has steps. Are you sure?");
      if (!confirm) {
        return;
      }
    }
    let steps: any = [];
    (scenes || []).map((scene, idx) => {
      steps.push({
        name: "Step " + idx,
        commands: [
          {
            type: ProgramCommandType.OBS,
            settings: {
              sceneName: scene.name,
              volumeLevel: 100,
              shouldMuteMic: true,
            },
          },
        ],
      });
    });

    console.log(steps);

    setLoadedProgram({ ...loadedProgram, steps: steps });
  }

  // OBS & Firebase
  useEffect(() => {
    if (!scenes || scenes.length === 0) {
      console.log("scenes already");
      return;
    } else if (Object.keys(sceneImages).length > 0) {
      console.log("scene images already");
      return;
    } else if (!isConnected || !loadedProgram) {
      console.log("not connected");
      return;
    }
    let images: any = new Object();
    let sceneCalls = scenes.map(async (scene) => {
      let imgUrl = await getScenePreview(scene.name);
      if (imgUrl) {
        // console.log("have img");
        images[scene.name] = imgUrl;
      }
    });
    Promise.all(sceneCalls).then(() => {
      // console.log("all done");
      // console.log(images);
      setSceneImages(images);
    });
  }, [isConnected, scenes, loadedProgram]);

  // Firebase
  useEffect(() => {
    console.log(Object.keys(sceneImages).length);
    if (isConnected && loadedProgram.programStep > -1 && sceneImages) {
      console.log("starting");
      startProgramAtStep(Number(loadedProgram.programStep));
    }
  }, [isConnected, loadedProgram, sceneImages]);

  async function reconnect() {
    console.log("reconnecting");
    connect()
      .then((response: any) => {
        if (response !== undefined) {
          setTimeout(reconnect, 4000);
        }
      })
      .catch(() => {
        setTimeout(reconnect, 4000);
      });
  }

  /**
   * Begins executing a program.
   * In the future, this might check with other systems before starting,
   * track our own timeline of events for advanced processing, or let
   * other users know what's happening via direct message or chat.
   */
  async function startProgram() {
    startProgramAtStep(0);
    updateProgramStep(0);
  }

  async function startProgramAtStep(stepNumber: number) {
    setIsEditMode(false);
    setProgramStep(stepNumber);
    walkStep(stepNumber);

    setPreviewPrev(getPreviewImageForStep(stepNumber - 1));
    setPreviewCurr(getPreviewImageForStep(stepNumber));
    setPreviewNext(getPreviewImageForStep(stepNumber + 1));
  }

  async function stopProgram() {
    setProgramStep(-1);
    setIsEditMode(true);
    setPreviewPrev("");
    setPreviewCurr("");
    setPreviewNext("");
    updateProgramStep(-1);
  }

  /**
   * Iterates program steps and handles end-of-program behavior.
   */
  async function continueProgram() {
    if (programStep < loadedProgram.steps.length - 1) {
      setProgramStep(programStep + 1);
      walkStep(programStep + 1);

      setPreviewPrev(previewCurr);
      setPreviewCurr(previewNext);
      setPreviewNext(getPreviewImageForStep(programStep + 2));

      updateProgramStep(programStep + 1);
    } else {
      stopProgram();
    }
  }

  async function reverseProgram() {
    if (programStep < 1) {
      stopProgram();
    } else {
      setProgramStep(programStep - 1);
      walkStep(programStep - 1);

      setPreviewPrev(getPreviewImageForStep(programStep - 2));
      setPreviewCurr(previewPrev);
      setPreviewNext(previewCurr);

      updateProgramStep(programStep - 1);
    }
  }

  function getPreviewImageForStep(stepNumber: number): string {
    if (stepNumber > loadedProgram.steps.length - 1 || stepNumber < 0) {
      return "";
    }
    let step = loadedProgram.steps[stepNumber];
    let result = "";
    step.commands.map((cmd) => {
      if (cmd.type === ProgramCommandType.OBS) {
        result = cmd.settings.sceneName + "";
      }
    });
    return result;
  }

  async function saveProgram() {
    // set without merge will overwrite a document or create it if it doesn't exist yet
    // set with merge will update fields in the document or create it if it doesn't exists
    // update will update fields but will fail if the document doesn't exist
    // create will create the document but fail if the document already exists
    db.collection("programs")
      .doc(pId)
      .set(loadedProgram, { merge: true })
      .then((res) => {
        console.log(res);
      });
  }

  async function updateProgramStep(programStep: number) {
    db.collection("programs")
      .doc(pId)
      .update({ programStep: programStep })
      .then((res) => {
        console.log("update program step");
        // console.log(res);
      });
  }

  async function fetchProgram(programId: string) {
    db.collection("programs")
      .doc(programId)
      .get()
      .then((res) => {
        let program = res.data() as Program;
        console.log(program);
        setLoadedProgram(program);
        setProgramStep(program.programStep);
      });
  }

  /**
   * For a given program step, executes all commands.
   * @param stepNumber
   */
  async function walkStep(stepNumber: number) {
    let step = loadedProgram.steps[stepNumber];
    step.commands.map((cmd) => {
      executeProgramCommand(cmd);
      // what about async commands? chained commands?
      // await callback
    });

    // should auto advance
  }

  // Have this handle Promise.all([async, async])
  async function executeProgramCommand(cmd: ProgramCommand) {
    if (cmd.type == ProgramCommandType.OBS) {
      runObsCommand(cmd.settings);
    } else if (cmd.type == ProgramCommandType.ZOOM) {
      runZoomCommand(cmd.settings);
    }
  }

  async function runObsCommand(settings: OBSCommandSettings) {
    if (settings.sceneName) {
      setScene(settings.sceneName, settings.shouldMuteMic);
    }

    if (settings.volumeLevel) {
      // https://github.com/Palakis/obs-websocket/blob/b02239f3e39cf59a57175dbfcd9130f74b895fe9/docs/generated/protocol.md#getvolume
    }
  }

  async function runZoomCommand(settings: ZoomCommandSettings) {
    let zoom = ZoomClient.getInstance();

    if (settings.shouldMuteOthers) {
      zoom.sendZoomCommand(ZoomCommands.MUTE_AUDIO, null, false);
    }
    if (settings.shouldUnmute) {
      zoom.sendZoomCommand(ZoomCommands.UNMUTE_AUDIO, settings.speakerId);
    }
    if (settings.shouldSpotlight) {
      zoom.sendZoomCommand(ZoomCommands.SPOTLIGHT_VIDEO, settings.speakerId);
      // "hideSpotlightVideo"
    }
    if (settings.shouldStartRecording) {
      zoom.sendZoomCommand(ZoomCommands.START_CLOUD_RECORDING);
    }
  }

  async function getAudience() {
    ZoomClient.getInstance();
    zoom
      .get("/participants")
      .then((response: any) => {
        return response.json();
      })
      .then((zoomUsers: any) => {
        setEventAudience(zoomUsers);
      });
  }

  async function sendCommand(command: any, params: Object) {
    try {
      return obs && (await obs.send(command, params || {}));
    } catch (e) {
      console.log("Error sending command", command, " - error is:", e);
      return {};
    }
  }

  async function getScenePreview(sceneName: string) {
    if (sceneName) {
      return sendCommand("TakeSourceScreenshot", {
        sourceName: sceneName,
        embedPictureFormat: "jpeg",
        width: 960,
        height: 540,
        compressionQuality: 50,
      }).then((data) => {
        return (data && data.img) || null;
      });
    }
    return null;
  }

  async function findSceneByName(name: string) {
    return (
      (scenes || []).filter((i: any) => {
        // OBSWebSocket.Scene
        return i.name === name;
      })[0] || null
    );
  }

  async function setScene(sceneName: string, muteMics: boolean = false) {
    let response = await sendCommand("SetCurrentScene", {
      "scene-name": sceneName,
    });
    setMute(muteMics);
  }

  async function setMute(muteStatus: boolean) {
    await setMuteStatus("Mic/Aux", muteStatus);
  }

  async function setMuteStatus(source: string, muteStatus: boolean) {
    await sendCommand("SetMute", {
      source: source,
      mute: muteStatus,
    });
  }

  async function setSceneItemFullscreen(sceneName: string, itemName: string) {
    await sendCommand("SetSceneItemProperties", {
      "scene-name": sceneName,
      item: {
        name: itemName as string,
      },
      bounds: {
        type: "OBS_BOUNDS_SCALE_INNER",
        x: 1920,
        y: 1080,
      },
    } as any);
  }

  async function createSource() {
    let setVisible = true;
    let name = prompt("What's the source name?");
    let filename = prompt("What's the local file name?");
    let kind = "ffmpeg_source"; // ffmpeg_source, vlc_source
    let scene = currentScene + "";
    let settings = {
      close_when_inactive: true,
      local_file: filename,
      looping: true,
    };
    let response: any = sendCommand("CreateSource", {
      sourceName: name || "newname",
      sourceKind: kind,
      sceneName: scene,
      sourceSettings: settings,
      setVisible: setVisible,
    });
    console.log(response);
    if (response.status == "ok") {
      setSceneItemFullscreen(currentScene as string, name as string);
    }
  }

  async function SetSceneItemProperties() {}

  async function getSceneItemProperties(sceneName: string, itemName: string) {
    let response: any = sendCommand("GetSceneItemProperties", {
      "scene-name": sceneName,
      item: itemName as any,
    });
    console.log(response);
  }

  async function getSourceSettings(sourceName: string, sourceType?: string) {
    let response: any = sendCommand("GetSourceSettings", {
      sourceName: sourceName,
      sourceType: sourceType,
    });
    console.log(response);
  }

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      <div className="sticky top-0">
        <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {(programStep < 0 || !isConnected) && (
            <>
              {/* <div className="grid grid-cols-2 gap-1">
                <input
                  type="text"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 m-2"
                  disabled={isConnected}
                  value={obsUrl}
                  onChange={(event: any) => {
                    console.log(event?.target.value);
                    setObsUrl(event?.target.value);
                    setHost(event?.target.value);

                  }}
                  placeholder="obs host"
                />
                <input
                  type="text"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 m-2"
                  disabled={isConnected}
                  value={zoomUrl}
                  onChange={(event: any) => {
                    console.log(event.target);
                    let zurl = event?.target.value;
                    setZoomUrl(zurl);
                    zoom.init(zurl);
                  }}
                  placeholder="zoom host"
                />
              </div> */}
              <div className="grid grid-cols-1 gap-1">
                <button
                  className="btn btn-afterword m-2"
                  onClick={() => {
                    if (!isConnected) {
                      let status = connect();
                      status
                        ?.then((response: any) => {
                          if (response === undefined) {
                            // process Zoom
                            // getAudience();
                            // Give it a few seconds for the isConnected state to be set
                          } else {
                            response.description && alert(response.description);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error);
                          alert(error.description);
                        });
                    } else {
                      disconnect();
                    }
                  }}
                >
                  {isConnected ? "Disconnect" : "Connect"}
                </button>
                {shouldShowSteps && (
                  <>
                    <button
                      className="btn btn-green m-2"
                      onClick={() => {
                        saveProgram();
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-green m-2"
                      onClick={() => {
                        setProgramStepsFromScenes();
                      }}
                    >
                      Set Program Steps from Scenes
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div className="grid grid-cols-1 gap-1">
          {isConnected && programStep < 0 && (
            <button className="btn btn-green m-2" onClick={startProgram}>
              Start
            </button>
          )}
          {programStep > -1 && <img src={sceneImages[previewCurr]} />}
        </div>
        {isConnected && programStep > -1 && (
          <div className="grid grid-cols-2 gap-1">
            {programStep === 0 ? (
              <div></div>
            ) : (
              <button
                className="btn btn-afterword m-2"
                onClick={reverseProgram}
              >
                Previous
              </button>
            )}

            <button className="btn btn-afterword m-2" onClick={continueProgram}>
              {programStep === loadedProgram.steps.length - 1
                ? "Finish"
                : "Next"}
            </button>

            {/* <button
                    className="btn bg-red-500 text-white m-2"
                    onClick={stopProgram}
                  >
                    Stop
                  </button> */}
            {/* <button
                    className="btn btn-blue m-2"
                    onClick={() => {
                      setIsManuelMode(!isManualMode);
                    }}
                  >
                    {isManualMode ? "Toggle Program" : "Toggle Manual"}
                  </button> */}
          </div>
        )}
        <div className="grid grid-cols-2 gap-1">
          <img src={sceneImages[previewPrev]} onClick={reverseProgram} />
          <img src={sceneImages[previewNext]} onClick={continueProgram} />
        </div>
      </div>

      {isConnected && shouldShowSteps && (
        <>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Settings
                        </th>
                        <th
                          scope="col"
                          className="relative px-1 py-1 text-right"
                        >
                          {shouldShowSteps && (
                            <button
                              className="btn btn-blue m-2 text-xs"
                              onClick={(event) => {
                                // console.log("here");
                                let newSteps = loadedProgram.steps.concat([
                                  {
                                    name:
                                      "Step #" +
                                      (loadedProgram.steps.length + 1),
                                    commands: [],
                                  },
                                ]);
                                setLoadedProgram({
                                  ...loadedProgram,
                                  steps: newSteps,
                                });
                              }}
                            >
                              + Step
                            </button>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {loadedProgram.steps?.map((step, stepIdx) => {
                        return (
                          <>
                            <tr
                              key={stepIdx}
                              style={{
                                backgroundColor:
                                  stepIdx == programStep ? "yellow" : "",
                              }}
                            >
                              <td className="px-3 py-2 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-10 w-10 text-left text-lg">
                                    <button
                                      className="btn btn-blue text-xs"
                                      onClick={(event) => {
                                        // if we're running the program, assume we're calling an audible
                                        if (programStep > -1) {
                                          setProgramStep(stepIdx);
                                        }
                                        walkStep(stepIdx);
                                      }}
                                    >
                                      #{stepIdx + 1}
                                    </button>
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">
                                      {step.name}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {step.commands.length} step(s)
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap">
                                {shouldShowSteps && (
                                  <button
                                    className="btn btn-blue text-xs"
                                    onClick={(event) => {
                                      let newSteps = [...loadedProgram.steps];
                                      newSteps[stepIdx].commands =
                                        loadedProgram.steps[
                                          stepIdx
                                        ].commands.concat([
                                          {
                                            type: ProgramCommandType.NONE,
                                            settings: {} as any,
                                          },
                                        ]);
                                      setLoadedProgram({
                                        ...loadedProgram,
                                        steps: newSteps,
                                      });
                                    }}
                                  >
                                    Add Cmd
                                  </button>
                                )}
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap text-right">
                                {shouldShowSteps && (
                                  <button
                                    className="btn text-black text-xs mr-2"
                                    onClick={(event) => {
                                      let confirm =
                                        window.confirm("Are you sure?");
                                      if (!confirm) {
                                        return;
                                      }
                                      let newProgram = { ...loadedProgram };
                                      newProgram.steps.splice(stepIdx, 1);
                                      setLoadedProgram({ ...loadedProgram });
                                    }}
                                  >
                                    Remove Step
                                  </button>
                                )}
                              </td>
                            </tr>

                            {step.commands.map((cmd, cmdIdx) => {
                              return ProgramCommandComponent(
                                cmd,
                                cmdIdx,
                                stepIdx,
                                loadedProgram,
                                setLoadedProgram
                              );
                            })}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isConnected && isManualMode && shouldShowSteps && (
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
          <div>
            <h1>Video Production</h1>
            {/* <button
              className="btn btn-blue"
              onClick={async () => {
                createSource();
              }}
            >
              Create Source
            </button> */}
            <h4>Scenes</h4>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-2">
              {scenes &&
                scenes.map((scene) => {
                  return (
                    <>
                      <button
                        className={
                          "btn mr-2 " +
                          (currentScene == scene.name
                            ? "bg-green-500 text-white"
                            : "btn-blue")
                        }
                        key={scene.name}
                        onClick={(event: any) => {
                          setScene(scene.name);
                        }}
                      >
                        {scene.name}
                      </button>
                      {/* <img src={previewImages[scene.name]} /> */}
                    </>
                  );
                })}
            </div>
            {/* <h4>Sources</h4>
            <div className="grid grid-cols-4 gap-2">
              {sources &&
                sources.map((source) => {
                  return (
                    <button
                      className="btn btn-blue  mr-2 mb-2"
                      onClick={async (event: any) => {
                        let settings: any = await getSceneItemProperties(
                          currentScene as string,
                          source.name
                        );
                      }}
                      key={source.name}
                    >
                      {source.name} ({source.type} / {source.type}) */}
            {/* // In future versions of OBS Websocket, we'll care about `typeid` */}
            {/* </button>
                  );
                })}
            </div> */}
          </div>
          {/* <div>
            <h1>Video Event Mgmt</h1>
            <div className="grid grid-cols-4 gap-2">
              <button className="btn btn-blue" onClick={getAudience}>
                Get Audience
              </button>
              <button
                className="btn btn-blue ml-2"
                onClick={() => {
                  zoom.sendZoomCommand(ZoomCommands.MUTE_AUDIO, null, false);
                }}
              >
                Mute All
              </button>
            </div>
            {eventAudience.map((user: any) => {
              return (
                <p key={user.userID}>
                  {user.userName} - {user.userID} -{" "}
                  {user.isHost ? "(Host) - " : ""}{" "}
                  {user.isAudioMuted ? "(Muted) - " : ""}{" "}
                  {user.isVideoOn ? "(Video) - " : ""}{" "}
                  <button
                    className="btn btn-blue"
                    onClick={() => {
                      zoom.sendZoomCommand(
                        ZoomCommands.MUTE_AUDIO,
                        user.userID,
                        true
                      );
                    }}
                  >
                    Mute
                  </button>
                  <button
                    className="btn btn-blue"
                    onClick={() => {
                      zoom.sendZoomCommand(
                        ZoomCommands.UNMUTE_AUDIO,
                        user.userID
                      );
                    }}
                  >
                    Unmute
                  </button>
                </p>
              );
            })}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ProgramPage;
