import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/auth";
import "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { AuthFlowContainer } from "./AuthFlow";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Routes } from "../../../config/constants";
import EmailIcon from "@material-ui/icons/Email";
import * as Sentry from "@sentry/react";

const EmailLinkAuth = () => {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const history = useHistory();
  const { setTosSigned } = useContext(AuthContext);

  const db = firebase.firestore();

  useEffect(() => {
    if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
      console.log("not a sign-in link");
      history.push(Routes.LOGIN);
    }

    var email = window.localStorage.getItem("emailForSignIn");
    if (email) {
      console.log("Skipping email verification for: ", email);
      verifyEmail(email);
    } else {
      setShowEmailInput(true);
    }
  }, []);

  const verifyEmail = (email: string) => {
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(async (result) => {
        // You can access the new user via result.user
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        if (result.user) {
          const userRef = (await db
            .collection("users")
            .doc(String(result.user.uid))
            .get()) as any | undefined;

          // check for ToS here, then move to family dashboard
          if (!userRef) {
            //
          }
          if (userRef.exists && userRef.data().tosSigned) {
            // we're already good, let's finish the login
            history.push(Routes.HOME_PLANNER);
          } else {
            let firebaseDate = firebase.firestore.Timestamp.fromDate(
              new Date()
            );
            await db.collection("users").doc(String(result.user.uid)).update({
              tosSigned: firebaseDate,
            });
            setTosSigned(firebaseDate);
            history.push(Routes.HOME_PLANNER);
          }
        }
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log("Error completing sign-in:", error);
        try {
          Sentry.setUser({ email: email });
          Sentry.captureMessage("Error completing sign-in");
          Sentry.captureException(error);
        } catch (err) {
          console.log("Sentry error");
        }
        if (error.code && error.code === "auth/invalid-action-code") {
          setMessage(
            "This sign in link has expired or was already used. Please click the button below to get a new link."
          );
          window.localStorage.setItem("emailForSignIn", email.toLowerCase());
        } else if (error.code && error.code === "auth/invalid-email") {
          setMessage(
            "This email does not match the sign-in email. Please try entering a different email."
          );
          setShowEmailInput(true);
          // FirebaseError: Requested entity was not found.
        } else if (error.code && error.code === "auth/expired-action-code") {
          setMessage(
            "This login link has expired. Please click the button below to get a new link."
          );
          window.localStorage.setItem("emailForSignIn", email.toLowerCase());
        } else {
          setMessage("There was a problem with your sign in link.");
        }
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // The client SDK will parse the code from the link for you.

      // check in with ToS and present that to use when they verify
      verifyEmail(userEmail);
    }
  };

  return (
    <AuthFlowContainer
      cardTitle="Complete Login"
      cardFooter={
        <span>
          By continuing, you agree to our{" "}
          <Link target="_blank" href="//afterword.com/terms">
            Terms
          </Link>{" "}
          &{" "}
          <Link target="_blank" href="//afterword.com/privacy">
            Privacy Policy
          </Link>
        </span>
      }
    >
      {showEmailInput && (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography>
                For your security, please confirm your email
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                required
                id="standard-basic"
                label="Email Address"
                type="email"
                name="email"
                variant="standard"
                className="block"
                value={userEmail}
                placeholder="your.email@domain.com"
                onChange={(event) => setUserEmail(event.target.value)}
                fullWidth={true}
              />
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" type="submit">
                Confirm
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {message && (
        <>
          <Grid item>
            <Grid item>
              <Typography variant="caption">{message}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(Routes.EMAIL_ME_A_LINK);
              }}
              startIcon={<EmailIcon />}
            >
              Email me a login link
            </Button>
          </Grid>
        </>
      )}
    </AuthFlowContainer>
  );
};

export default EmailLinkAuth;
