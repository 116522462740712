import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/auth";
import "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { AuthFlowContainer } from "../components/AuthFlow";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import EmailIcon from "@material-ui/icons/Email";
import { Routes } from "../../../config/constants";

library.add(faFacebookF, faGoogle);

interface UserData {
  email: string;
  password: string;
}

const Login = () => {
  const authContext = useContext(AuthContext);
  const { loadingAuthState } = useContext(AuthContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
  } as UserData);

  const db = firebase.firestore();

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (!result || !result.user || !firebase.auth().currentUser) {
          return;
        }

        return setUserProfile().then(() => {
          redirectToTargetPage();
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  const setUserProfile = async () => {
    if (await isUserExists()) {
      return;
    }

    // Transitioning to users
    return await db
      .collection("users")
      .doc(firebase.auth().currentUser?.uid)
      .set({
        email: firebase.auth().currentUser?.email,
      })
      .then(() => {
        console.log("Saved");
        return;
      })
      .catch((error) => {
        console.log(error.message);
        alert(error.message);
      });
  };

  const isUserExists = async () => {
    const doc = await db
      .collection("users")
      .doc(firebase.auth().currentUser!.uid)
      .get();
    return doc.exists;
  };

  const redirectToTargetPage = () => {
    history.push(Routes.ADMIN_HOME);
  };

  const handleClick = () => {
    history.push(Routes.SIGNUP);
  };

  const handleChange = (event: any) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();

  //   firebase
  //     .auth()
  //     .signInWithEmailAndPassword(values.email, values.password)
  //     .then((res) => {
  //       authContext.setUser(res);
  //       console.log(res, "res");
  //       history.push(Routes.HOME);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       alert(error.message);
  //     });
  // };

  const handleSocialClick = (sns: any) => {
    console.log(sns, "sns");

    let provider: firebase.auth.AuthProvider;
    switch (sns) {
      // case "Facebook":
      //   provider = new firebase.auth.FacebookAuthProvider();
      //   console.log(provider, "fbprovider");
      //   break;

      case "Google":
        provider = new firebase.auth.GoogleAuthProvider();
        console.log(provider, "gprovider");
        break;

      default:
        throw new Error("Unsupported SNS" + sns);
    }

    firebase.auth().signInWithRedirect(provider).catch(handleAuthError);
  };

  const handleAuthError = (error: firebase.auth.Error) => {
    console.log(error);
  };

  return (
    <AuthFlowContainer
      cardTitle="Login"
      cardFooter={
        <span>
          By continuing, you agree to our{" "}
          <Link target="_blank" href="//afterword.com/terms">
            Terms
          </Link>{" "}
          &{" "}
          <Link target="_blank" href="//afterword.com/privacy">
            Privacy Policy
          </Link>
        </span>
      }
    >
      <Grid item>
        <Typography>Please log in to continue</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EmailIcon />}
          onClick={() => {
            history.push(Routes.EMAIL_ME_A_LINK);
          }}
        >
          Email me a login link
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            <FontAwesomeIcon className="mr-2" icon={["fab", "google"]} />
          }
          onClick={() => handleSocialClick("Google")}
        >
          Sign in with Google
        </Button>
      </Grid>
      {/* <Grid item>
        <Typography variant="caption">
          Don't have an account? <Link href={Routes.SIGNUP}>Sign Up</Link>
        </Typography>
      </Grid> */}
    </AuthFlowContainer>
  );
};

export default Login;
