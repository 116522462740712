import React, { useContext } from "react";

import { AuthContext } from "../../../AuthProvider";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

export const AuthFlowContainer = (props: any) => {
  let { children, cardTitle, cardFooter } = props;

  const { loadingAuthState } = useContext(AuthContext);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        style={{ textAlign: "center", width: "400px", maxWidth: "100%" }}
      >
        {loadingAuthState && "Loading..."}
        {!loadingAuthState && (
          <>
            <Grid item>
              <img style={{ margin: "0 auto" }} width="130px" src="/logo.png" />
            </Grid>
            <Card style={{ padding: "40px 30px", margin: "20px" }}>
              <CardHeader
                title={cardTitle || ""}
                style={{ padding: "0", marginBottom: "40px" }}
              />
              <Grid
                container
                direction="column"
                justify="space-between"
                spacing={3}
              >
                {children}
              </Grid>
            </Card>

            <Grid item>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                {cardFooter}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
