import React, { useEffect, useState } from "react";
import firebase from "./firebase";
import * as Sentry from "@sentry/react";

type ContextProps = {
  user: firebase.User | null;
  userValues: any;
  authenticated: boolean;
  isAdmin: boolean;
  userRoles: Array<string>;
  tosSigned: any;
  setUser: any;
  loadingAuthState: boolean;
  setTosSigned: any;
};

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userValues, setUserValues] = useState({} as any);
  const [userRoles, setUserRoles] = useState([]);
  const [tosSigned, setTosSigned] = useState("");
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const db = firebase.firestore();

  const verifyUser = async (user: any) => {
    // Check if user is in DB and isAdmin
    const userRef = (await db
      .collection("users")
      .doc(String(user.uid))
      .get()) as any | undefined;
    if (userRef.exists) {
      let userData = userRef.data();
      setIsAdmin(userData.isAdmin);
      console.log("admin ", userData.isAdmin);
      setTosSigned(userData.tosSigned || false);
      setUserRoles(userData.userRoles);
      setUserValues(userData);
      console.log("user roles", userData.userRoles);
    }
    setLoadingAuthState(false);
    console.log(user, "ap user");
    console.log(user !== null, "ap authenticated");
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user: any) => {
      setUser(user);
      if (!user) {
        setLoadingAuthState(false);
        return;
      }
      verifyUser(user);

      if (Sentry) {
        Sentry.setUser({ email: user.email });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        userValues,
        authenticated: user !== null,
        isAdmin,
        userRoles,
        tosSigned,
        setUser,
        loadingAuthState,
        setTosSigned,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
