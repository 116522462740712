import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { API_BASE, getAppBase } from "@afterword/core/utils";
import { Routes, UserRoles } from "../../../config/constants";
import * as server from "../../../services/server";
import Button from "@material-ui/core/Button";

const db = firebase.firestore();

const CreateUser = () => {
  const { userId } = useParams();
  const history = useHistory();

  const [userEmail, setUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signInLink, setSignInLink] = useState("");
  const [humanId, setHumanId] = useState("");
  const [allHumans, setAllHumans] = useState([] as any);
  // Array of maps representing connected human pages and corresponding roles (i.e. [{humanID1: role1}, {humanID2:role2}, ...])
  const [connectedHumans, setConnectedHumans] = useState({} as any);
  const [role, setRole] = useState("");
  const [tosSigned, setTosSigned] = useState("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // creates new user and generates sign in link
    createNewUser();
  };

  useEffect(() => {
    const fetchHumans = async () => {
      const humansCollection = await db
        .collection("humans")
        .orderBy("firstName")
        .get();
      setAllHumans(humansCollection.docs);
    };
    fetchHumans();
  }, []);

  useEffect(() => {
    fetchAndSetHuman();
  }, []);

  const fetchAndSetHuman = async () => {
    console.log("user id", userId);
    const userData = await db.collection("users").doc(String(userId)).get();
    if (!userData.exists) {
      // creating new human entity
      console.log("No such document!");
      return;
    } else {
      let user: any = userData.data();
      console.log("data", user);
      console.log("first", user.firstName);
      setUserEmail(user.email || "");
      setFirstName(user.firstName || "");
      setLastName(user.lastName || "");
      setTosSigned(user.tosSigned || "");
      setConnectedHumans(user.connectedHumans || {});
      if (user.connectedHumans) {
        var listOfHumans = Object.keys(user.connectedHumans);
        setHumanId(String(listOfHumans[0]) || "");
        var listOfRoles = Object.values(user.connectedHumans);
        setRole(String(listOfRoles[0]) || "viewer");
      } else {
        setHumanId("");
        setRole("viewer");
      }
    }
  };

  const createNewUser = () => {
    server
      .post(`${API_BASE}/userCreation/createNewUser`, {
        email: userEmail,
        firstName: firstName,
        lastName: lastName,
      })
      .then(async (data) => {
        // check if new user successful
        if (data.success) {
          // add new user to users collection
          console.log("new user created", data.newUser);
          // generate sign in link
          // redo order here?
          generateSignInLink(data.newUser);
          saveUser(data.newUser);
          history.push(Routes.ADMIN_USERS + "/edit/" + data.newUser);
        } else {
          if (
            data.error ==
            "Error: The email address is already in use by another account."
          ) {
            console.log("user already existed");
            console.log(data);
            // generate sign in link
            generateSignInLink(userId);
          } else {
            console.log(
              "ERROR: an error occured when creating a new user, check function logs"
            );
          }
        }
      })
      .catch((error) => {
        if (
          error.error ==
          "Error: The email address is already in use by another account."
        ) {
          console.log("user already existed");
          generateSignInLink(userId);
        } else {
          console.log(
            "ERROR: an error occured when creating a new user, check function logs",
            error
          );
        }
      });
  };

  const generateSignInLink = (userId: any) => {
    console.log("generating sign in link");
    server
      .post(`${API_BASE}/userCreation/generateSignInLink`, {
        email: userEmail,
        redirectUrl: `${getAppBase()}${Routes.VERIFY_EMAIL}`,
      })
      .then(async (data) => {
        console.log("sign in link", data);
        setSignInLink(data.link);
      })
      .catch((error) => {
        console.log("Error occured while generating sign in link:", error);
      });
  };

  const saveUser = async (userId: any) => {
    const docRef = db.collection("users").doc(String(userId));
    if ((await docRef.get()).exists) {
      console.log("exists");
      await db.collection("users").doc(String(userId)).update({
        firstName: firstName,
        lastName: lastName,
        email: userEmail,
        connectedHumans: connectedHumans,
        tosSigned: tosSigned,
      });
    } else {
      await db
        .collection("users")
        .doc(String(userId))
        .set({
          firstName: firstName,
          lastName: lastName,
          email: userEmail,
          connectedHumans: connectedHumans,
          tosSigned: tosSigned,
          userRoles: [UserRoles.FAMILY],
          createdDate: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    }

    // add user to the selected human in the humans collection
    for (let humanKey in connectedHumans) {
      console.log("hid", humanKey);
      const humanDocRef = db.collection("humans").doc(String(humanKey));
      const humanDoc = await humanDocRef.get();
      let human: any = humanDoc.data();
      const connectedUsers = human.connectedUsers || {};
      console.log("new uid", userId);
      connectedUsers[String(userId)] = connectedHumans[humanKey];
      console.log(connectedUsers);
      await humanDocRef.update({
        connectedUsers: connectedUsers,
      });
    }
  };

  const sendSignInLink = () => {
    server
      .post(`${API_BASE}/userCreation/sendSignInLink`, {
        firstName: firstName,
        email: userEmail,
        signInLink: signInLink,
      })
      .then(async (data) => {
        console.log("data", data);
        console.log("sent!");
      })
      .catch((error) => {
        console.log("Error occured while sending sign in-link:", error);
      });
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>
        <strong>Create New User Account</strong>
      </h1>
      <div className="verifyBox" style={{ textAlign: "left" }}>
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "1000px", margin: "0 auto" }}
          className="p-4"
        >
          <input
            type="text"
            className="block verifyEmail"
            placeholder="Email"
            value={userEmail}
            onChange={(event) => setUserEmail(event.target.value.toLowerCase())}
          />
          <br />
          <div style={{ display: "inline-block" }}>
            <input
              type="text"
              className="block verifyEmail"
              value={firstName}
              placeholder="First name"
              onChange={(event) => setFirstName(event.target.value)}
            />
          </div>
          <div style={{ display: "inline-block" }}>
            <input
              type="text"
              className="block verifyEmail"
              value={lastName}
              placeholder="Last name"
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <br />
          <label style={{ color: "gray" }}>Corresponding human</label>
          <br />
          <select
            name="human-select"
            id="human-select"
            className="span5 verifyEmail"
            onChange={(event) => {
              // TODO: allow for multiple connected humans
              let selectedHumanIDvar = event.target.value;

              if (!selectedHumanIDvar) {
                setHumanId("");
                setConnectedHumans({});
                return;
              }

              setHumanId(selectedHumanIDvar);
              // Default to viewer role
              var connectedHumansObj: any = {};
              connectedHumansObj[selectedHumanIDvar] = role || "editor";
              setConnectedHumans(connectedHumansObj);
            }}
            value={humanId}
          >
            <option value=""></option>
            {allHumans.map((doc: any) => {
              let data = doc.data();
              return <option value={doc.id}>{data.pageSlug}</option>;
            })}
          </select>
          <br />
          <label style={{ color: "gray" }}>Role</label>
          <br />
          <select
            className="span5 verifyEmail"
            id="human-select"
            onChange={(event) => {
              console.log("changing role");
              // TODO: allow for multiple humans to be selected each with a different role
              // Currently, default to the state but need to be able to identify and update a specific humanID in the list
              var selectedRole = event.target.value;
              setRole(event.target.value);
              let currentConnectedHumans = connectedHumans;
              currentConnectedHumans[humanId] = selectedRole;
              setConnectedHumans(currentConnectedHumans);
            }}
            value={role}
          >
            <option value=""></option>
            <option value="editor">Editor</option>
            <option value="viewer">Viewer</option>
          </select>

          <br />
          <br />
          <input
            type="submit"
            className="btn btn-afterword"
            value="Generate sign in link"
          />
          {userId && (
            <Button
              onClick={() => {
                saveUser(userId);
              }}
            >
              Save
            </Button>
          )}
        </form>
      </div>
      <div className="verifyBox">
        <br />
        Sign In Link <br />
        <input
          id="magicSignInLink"
          className="verifyEmail"
          type="text"
          value={signInLink}
        />
        <button onClick={sendSignInLink}>Send Sign-in Link</button>
      </div>
    </div>
  );
};

export default CreateUser;
