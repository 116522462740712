import React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "../../../config/constants";
import { Dashboard } from "../components";
import { HumansRoutes } from "../../Humans";
import { UsersRoutes } from "../../Users";

import { Layout } from "../../../Layout";

export const DashboardRoutes = () => {
  console.log("dashboard");
  return (
    <Layout>
      <Switch>
        <Route exact path={Routes.ADMIN_HOME} component={Dashboard} />
        <Route path={Routes.ADMIN_USERS} component={UsersRoutes} />
        <Route path={Routes.ADMIN_HUMANS} component={HumansRoutes} />
        <Route path={Routes.ADMIN_OAUTH} component={HumansRoutes} />
      </Switch>
    </Layout>
  );
};
