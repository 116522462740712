import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import uuid from "react-uuid";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { createArrowFunction } from "typescript";
import { API_BASE } from "@afterword/core/utils";
import HumanBio from "../../Humans/components/humanBio";
import HumanMemorial from "../../Humans/components/humanMemorial";
import HumanMedia from "../../Humans/components/humanMedia";
import HumanConfirm from "../../Humans/components/humanConfirm";
import HumanPostService from "../../Humans/components/humanPostService";
import HumanAdmin from "../../Humans/components/humanAdmin";

import { HumanContext, HumanProvider } from "../../../HumanProvider";
import { AuthContext } from "../../../AuthProvider";

// Material UI for tabs
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

interface RecordingBlob {
  url: string;
  poster: string;
  title: string;
}

const HumanInfo = ({ userId, humanId, viewOnly }: any) => {
  const { humanModel, setHumanModel, saveStatus, updateFirestore, isLoaded } =
    useContext(HumanContext);
  const { user } = useContext(AuthContext);

  // Material UI Tabs
  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#F7F7F7",
    },
    tab: {
      "& .MuiBox-root": {
        padding: "0px",
      },
    },
  }));

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <HumanProvider humanId={humanId}>
        {/* <AppBar position="static">
          <Tabs value={value}>
            <Tab label="Agreement" {...a11yProps(0)} />
          </Tabs>
        </AppBar> */}
        <TabPanel index={0} value={value}>
          <HumanConfirm
            userId={(user && user.uid) || null}
            humanId={humanId}
            viewOnly={viewOnly}
          />
        </TabPanel>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Biography" {...a11yProps(0)} />
          <Tab label="Media" {...a11yProps(1)} />
          <Tab label="Post Service" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <HumanBio humanId={humanId} viewOnly={viewOnly} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HumanMedia humanId={humanId} viewOnly={viewOnly} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HumanPostService humanId={humanId} viewOnly={viewOnly} />
      </TabPanel> */}
      </HumanProvider>
    </div>
  );
};

export default HumanInfo;
