import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import HumanPostService from "./humanPostService";
import uuid from "react-uuid";
import "quill/dist/quill.snow.css";
import { API_BASE } from "@afterword/core/utils";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { HumanTimeline } from "../components/humanTimeline";

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export enum SaveStatus {
  NEW = "new",
  LOADING = "loading",
  LOADED = "loaded",
  SAVING = "saving",
  SAVED = "saved",
  SAVING_ERROR = "saving_error",
}

interface RecordingBlob {
  url: string;
  poster: string;
  title: string;
}

interface ZoomSettings {
  url: string;
  id: string;
  phone: string;
  passcode: string;
}

function exportCsv(
  arrayHeader: Array<string>,
  arrayKeys: Array<string>,
  arrayData: Array<any>,
  delimiter: string,
  fileName: string
) {
  let header = arrayHeader.join(delimiter) + "\n";
  let csv = header;
  arrayData.forEach((obj: any) => {
    let row: Array<string> = [];
    // Escape quote marks with double quote marks
    // Wrap every value in quotes to handle commas
    arrayKeys.forEach((key: string) => {
      row.push(`"${(obj[key] || "").replace(/"/g, '""')}"`);
    });
    csv += row.join(delimiter) + "\n";
  });

  let csvData = new Blob([csv], { type: "text/csv" });
  let csvUrl = URL.createObjectURL(csvData);

  let hiddenElement = document.createElement("a");
  hiddenElement.href = csvUrl;
  hiddenElement.target = "_blank";
  hiddenElement.download = fileName + ".csv";
  hiddenElement.click();
}

const HumanAdmin = ({ humanId }: any) => {
  const history = useHistory();

  const [variant, setVariant] = useState("");
  const [formattedDatetime, setFormattedDatetime] = useState(""); // String: YYYY-MM-dd T HH:mm:ssZ
  const [zoomSettings, setZoomSettings] = useState({
    url: "",
    id: "",
    phone: "",
    passcode: "",
  } as ZoomSettings);
  const [isPublished, setIsPublished] = useState(false);
  const [showRsvp, setShowRsvp] = useState(false);
  const [useJoinFlow, setUseJoinFlow] = useState(false);
  const [storiesQuestionUrl, setStoriesQuestionUrl] = useState("");
  const [storiesAnswersUrl, setStoriesAnswersUrl] = useState("");
  const [imageAsFile, setImageAsFile] = useState("");
  const [obituaryOverride, setObituaryOverride] = useState("");
  const [packageNameAndPrice, setPackageNameAndPrice] = useState("");
  const [packageServices, setPackageServices] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [recordingEmbedUrl, setRecordingEmbedUrl] = useState("");
  const [recordingBlobs, setRecordingBlobs] = useState(
    [] as Array<RecordingBlob>
  );
  const [saveStatus, setSaveStatus] = useState(SaveStatus.NEW);
  const [lastModifiedDate, setLastModifiedDate] = useState("");

  useEffect(() => {
    fetchAndSetHuman();
  }, []);

  const fetchAndSetHuman = async () => {
    setSaveStatus(SaveStatus.LOADING);
    const humanData = await db.collection("humans").doc(String(humanId)).get();
    if (!humanData.exists) {
      // creating new human entity
      console.log("No such document!");
      return;
    } else {
      let human: any = humanData.data();

      // Strings
      setVariant(human.variant || "");
      setObituaryOverride(human.obituaryOverride || "");
      setPackageNameAndPrice(human.packageNameAndPrice || "");
      setPackageServices(human.packageServices || "");
      setInvoiceUrl(human.invoiceUrl || "");
      setRecordingEmbedUrl(human.recordingEmbedUrl || "");

      // Urls
      setStoriesAnswersUrl(human.storiesAnswersUrl || "");
      setStoriesQuestionUrl(human.storiesQuestionUrl || "");

      // Objects
      setZoomSettings(human.zoomSettings || {});
      setRecordingBlobs(human.recordingBlobs || []);

      //Dates
      setFormattedDatetime(timestampToDatetime(human.memorialDatetime));

      // Bool
      setShowRsvp(!!human.rsvp);
      setUseJoinFlow(!!human.useJoinFlow);

      setSaveStatus(SaveStatus.LOADED);
    }
  };

  const timestampToDatetime = (ts: firebase.firestore.Timestamp) => {
    return !ts ? "" : new Date(ts.seconds * 1000).toISOString().substr(0, 23);
  };

  const getHumanFromState = () => {
    // transform date type variables
    let lastModifiedDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date()
    );

    return {
      zoomSettings: zoomSettings,
      variant: variant,
      rsvp: showRsvp,
      obituaryOverride: obituaryOverride,
      packageNameAndPrice: packageNameAndPrice,
      packageServices: packageServices,
      invoiceUrl: invoiceUrl,
      useJoinFlow: useJoinFlow,
      storiesQuestionUrl: storiesQuestionUrl,
      storiesAnswersUrl: storiesAnswersUrl,
      recordingBlobs: recordingBlobs,
      recordingEmbedUrl: recordingEmbedUrl,
      lastModifiedDate: lastModifiedDateTimestamp,
    };
  };

  const handleImageUpload = async (event: any) => {
    event.preventDefault();
    const image = event.target.files[0];
    console.log(image);
    setImageAsFile(image);
    return await saveImageToFirebase(image);
  };

  const saveImageToFirebase = async (image: any) => {
    var newMetadata = {
      cacheControl: "public,max-age=4000",
    };
    const fileRef = storageRef.child(`humans/${humanId}/` + uuid());
    await fileRef.put(image, newMetadata);
    let newDownloadURL = await fileRef.getDownloadURL();
    console.log(newDownloadURL);
    return String(newDownloadURL);
  };

  /**
   * Make zoom API Post request using express firebase functions including zoom oauth flow
   * zoomPostType(string): specifies specfic type of post request. should check for types to call respective function
   * zoomPostType options: scheduleNewMeeting
   */
  const zoomAPIPost = (zoomPostType: String) => {
    // 1. Oauth
    // Calculate expiration date for access token
    var now = new Date();
    var curr = now.getTime() / 1000;
    var curr_string = curr.toString();
    var currExp: string | null = localStorage.getItem("accessTokenExpiration");

    if (
      currExp != null &&
      parseFloat(curr_string) < parseFloat(String(currExp))
    ) {
      // Access token still valid
      console.log("no oauth needed");
      if (zoomPostType == "scheduleNewMeeting") {
        scheduleZoomMeeting();
      }
    } else {
      var oldExpDate = localStorage.getItem("accessTokenExpiration");
      // Need new access token
      const url = `${API_BASE}/zoomCalls/zoomOauthFlow`;
      // const url = "https://us-central1-afterword-sandbox.cloudfunctions.net/zoomCalls/zoomOauthFlow";
      var oauthWindow: any = window.open(url, "_blank");

      window.addEventListener("storage", () => {
        if (oldExpDate != localStorage.getItem("accessTokenExpiration")) {
          oauthWindow.close();
          // 2. Make Zoom API POST depending on param
          if (zoomPostType == "scheduleNewMeeting") {
            scheduleZoomMeeting();
          }
        }
      });
    }
  };

  const scheduleZoomMeeting = async () => {
    // check for meeting details
    if (formattedDatetime != "") {
      // update error message if currently displayed
      var errorMessage = document.getElementById("scheduleMeetingError");
      if (errorMessage) {
        errorMessage.style.display = "none";
      }
      // temp testing options
      const requestOptions: any = {
        json: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_token: String(localStorage.getItem("accessToken")),
          options: {
            url: "https://api.zoom.us/v2/users/avamrobinson@gmail.com/meetings",
            json: true,
            body: {
              // topic: firstName + " " + lastName + " Memorial Service",
              topic: "Memorial Service",
              type: 2, // 2: scheduled meeting
              start_time: formattedDatetime, // YYYY-MM-DDTHH:mm:ssZ (in GMT timezone)
              // duration: 180, //TODO: change this or remove
              timezone: "UTC",
            },
          },
        }),
      };
      //"https://us-central1-afterword-sandbox.cloudfunctions.net/zoomCalls/zoomAPICall",
      fetch(`${API_BASE}/zoomCalls/zoomAPICall`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          let newZoomSettings = { ...zoomSettings };
          newZoomSettings.url = data.join_url;
          newZoomSettings.id = data.id;
          newZoomSettings.passcode = data.encrypted_password;
          setZoomSettings(newZoomSettings);
        });
    } else {
      // date, time not given --> display error message
      console.log(
        "ERROR: Date time field and main timezone required to schedule zoom meeting"
      );
      var errorMessage = document.getElementById("scheduleMeetingError");
      if (errorMessage) {
        errorMessage.style.display = "block";
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    var newHumanRef = db.collection("humans").doc(String(humanId));

    setSaveStatus(SaveStatus.SAVING);
    newHumanRef
      .update(getHumanFromState())
      .then(() => {
        setSaveStatus(SaveStatus.SAVED);
      })
      .catch((err) => {
        console.log("error", "=>", err);
        setSaveStatus(SaveStatus.SAVING_ERROR);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={9}>
            <Card style={{ padding: "40px" }}>
              <CardHeader title="Data" style={{ padding: "0px" }} />
              <a
                className="text-blue-400"
                href={`https://console.firebase.google.com/u/0/project/afterword-prod/firestore/data/~2Fhumans~2F${humanId}`}
                target="_blank"
              >
                View in Firestore
              </a>
              <br />
              <br />
              <button
                type="button"
                className="btn btn-blue"
                onClick={async () => {
                  const snapshot = await db
                    .collection("humans")
                    .doc(String(humanId))
                    .collection("guestEvents")
                    .where("type", "==", "JOIN")
                    .get();
                  let guestEvents = snapshot.docs.map((doc) => doc.data());
                  exportCsv(
                    [
                      "Name",
                      "Email or Phone",
                      "Mailing Address",
                      "Private Message",
                    ],
                    ["name", "emailPhone", "address", "message"],
                    guestEvents,
                    ",",
                    humanId
                  );
                }}
              >
                Export Participant Data
              </button>
            </Card>
            <br />
            <Card style={{ padding: "40px" }}>
              <CardHeader title="Zoom" style={{ padding: "0px" }} />
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                {/* <Grid item sm={12} className="humanFormItem">
                  <button
                    type="button"
                    className="btn btn-blue text-s"
                    onClick={() => zoomAPIPost("scheduleNewMeeting")}
                  >
                    Create Zoom Meeting
                  </button>
                  <p id="scheduleMeetingError">
                    Memorial Date and Time and Main Timezone required to
                    schedule meeting.
                  </p>
                </Grid> */}
                <Grid item xs={6} sm={3} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Zoom URL:"
                    variant="filled"
                    className="block"
                    value={zoomSettings?.url}
                    onChange={(event) => {
                      let newZoomSettings = { ...zoomSettings };
                      newZoomSettings.url = event.target.value;
                      setZoomSettings(newZoomSettings);
                    }}
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Zoom Id:"
                    variant="filled"
                    className="block"
                    value={zoomSettings?.id}
                    onChange={(event) => {
                      let newZoomSettings = { ...zoomSettings };
                      newZoomSettings.id = event.target.value;
                      setZoomSettings(newZoomSettings);
                    }}
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Zoom Passcode:"
                    variant="filled"
                    className="block"
                    value={zoomSettings?.passcode}
                    onChange={(event) => {
                      let newZoomSettings = { ...zoomSettings };
                      newZoomSettings.passcode = event.target.value;
                      setZoomSettings(newZoomSettings);
                    }}
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Zoom Phone:"
                    variant="filled"
                    className="block"
                    value={zoomSettings?.phone}
                    onChange={(event) => {
                      let newZoomSettings = { ...zoomSettings };
                      newZoomSettings.phone = event.target.value;
                      setZoomSettings(newZoomSettings);
                    }}
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <Checkbox
                    checked={useJoinFlow}
                    color="default"
                    onChange={(_) => setUseJoinFlow(!useJoinFlow)}
                    inputProps={{ "aria-label": "checkbox with default color" }}
                  />
                  <label>Use Join Flow (must have Zoom URL)</label>
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <Checkbox
                    checked={showRsvp}
                    color="default"
                    onChange={(_) => setShowRsvp(!showRsvp)}
                    inputProps={{ "aria-label": "checkbox with default color" }}
                  />
                  <label>Enable RSVP (don't use this with Zoom)</label>
                </Grid>
              </Grid>
            </Card>
            <br />
            <Card style={{ padding: "40px" }}>
              <CardHeader title="Stories" style={{ padding: "0px" }} />
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                {/* Stories Question URL */}
                <Grid item sm={6} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Stories Question URL:"
                    variant="filled"
                    className="block"
                    value={storiesQuestionUrl}
                    onChange={(event) =>
                      setStoriesQuestionUrl(event.target.value)
                    }
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>

                {/* Stories Answers URL */}
                <Grid item sm={6} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Stories Answers URL:"
                    variant="filled"
                    className="block"
                    value={storiesAnswersUrl}
                    onChange={(event) =>
                      setStoriesAnswersUrl(event.target.value)
                    }
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
              </Grid>
            </Card>
            <br />
            <Card style={{ padding: "40px" }}>
              <CardHeader
                title="Other Page Settings"
                style={{ padding: "0px" }}
              />
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                <Grid item sm={12} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Obituary Override"
                    variant="filled"
                    className="block"
                    value={obituaryOverride}
                    onChange={(event) =>
                      setObituaryOverride(event.target.value)
                    }
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Recording Embed URL (use for YouTube)"
                    variant="filled"
                    className="block"
                    value={recordingEmbedUrl}
                    onChange={(event) =>
                      setRecordingEmbedUrl(event.target.value)
                    }
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Package Name and Price"
                    variant="filled"
                    className="block"
                    value={packageNameAndPrice}
                    onChange={(event) =>
                      setPackageNameAndPrice(event.target.value)
                    }
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    multiline
                    rowsMax={6}
                    label="Package Services"
                    variant="filled"
                    className="block"
                    value={packageServices}
                    onChange={(event) => setPackageServices(event.target.value)}
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <TextField
                    id="outlined-basic"
                    label="Invoice URL"
                    variant="filled"
                    className="block"
                    value={invoiceUrl}
                    onChange={(event) => setInvoiceUrl(event.target.value)}
                    fullWidth={true}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item sm={12} className="humanFormItem">
                  <h2>Template Name</h2>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    label="Page Template"
                    id="demo-simple-select-helper"
                    value={variant}
                    onChange={(event) => {
                      setVariant(event.target.value as any);
                    }}
                  >
                    <MenuItem value="">
                      <em>None (falls back to "default")</em>
                    </MenuItem>
                    <MenuItem value={"default"}>Default</MenuItem>
                    <MenuItem value={"centered"}>Centered</MenuItem>
                    <MenuItem value={"split"}>
                      Split - for two people at once
                    </MenuItem>
                    <MenuItem value={"paymentOverdue"}>
                      Overdue - only use this as a last resort
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Card>
            <br />
            <Card style={{ padding: "40px" }}>
              <CardHeader title="Recording Blobs" style={{ padding: "0px" }} />
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                {(recordingBlobs || []).map((blob, idx) => {
                  return (
                    <div className="grid grid-cols-4 gap-2 mb-4">
                      <div>
                        <label>Recording URL:</label>
                        <input
                          type="url"
                          className="block"
                          value={blob.url}
                          onChange={(event) => {
                            let newBlobs = recordingBlobs.concat([]);
                            newBlobs[idx].url = event.target.value;
                            setRecordingBlobs(newBlobs);
                          }}
                        />
                      </div>
                      <div>
                        <label>Recording Poster:</label>
                        <input
                          type="file"
                          className="block"
                          onChange={(event) => {
                            let downloadUrl = handleImageUpload(event);
                            downloadUrl.then((result) => {
                              let newBlobs = recordingBlobs.concat([]);
                              newBlobs[idx].poster = String(result);
                              setRecordingBlobs(newBlobs);
                            });
                          }}
                        />
                        {blob.poster && (
                          <a href={blob.poster} target="_blank">
                            <img
                              src={blob.poster}
                              style={{ maxWidth: "200px" }}
                            />
                          </a>
                        )}
                      </div>
                      <div>
                        <label>Recording title:</label>
                        <input
                          type="text"
                          className="block"
                          value={blob.title}
                          onChange={(event) => {
                            let newBlobs = recordingBlobs.concat([]);
                            newBlobs[idx].title = event.target.value;
                            setRecordingBlobs(newBlobs);
                          }}
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-green text-xs"
                          onClick={() => {
                            let shouldDelete = window.confirm("Are you sure?");
                            if (!shouldDelete) {
                              return;
                            }
                            let newBlob = recordingBlobs.concat([]);
                            newBlob.splice(idx, 1);
                            setRecordingBlobs(newBlob);
                          }}
                        >
                          remove recording
                        </button>
                      </div>
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="btn btn-grey text-xs"
                  onClick={() => {
                    let newBlob = recordingBlobs.concat({} as RecordingBlob);
                    setRecordingBlobs(newBlob);
                  }}
                >
                  add recording
                </button>
              </Grid>
            </Card>
          </Grid>
          <Grid xs={3}>
            <div className="save-container" style={{ textAlign: "center" }}>
              <Button color="primary" variant="contained" type="submit">
                Save
              </Button>
              <div className="text-gray-500 text-md">{saveStatus}</div>
              {saveStatus === SaveStatus.SAVING_ERROR && (
                <div className="text-red-500 text-lg">Error: check console</div>
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default HumanAdmin;
