import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import { Routes } from "../../../config/constants";

const Dashboard = () => {
  const [userName, setUserName] = useState();
  const history = useHistory();

  const handleClick = (event: any) => {
    event.preventDefault();

    firebase
      .auth()
      .signOut()
      .then((res) => {
        history.push(Routes.LOGIN);
      });
  };

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("users")
      .doc(firebase.auth().currentUser!.uid)
      .get()
      .then((res) => {
        const user = res.data();
        if (user) {
          setUserName(user.username);
        }
      });
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Admin Panel</h1>
      <div className="dash-btns">
        <button
          className="btn btn-lg btn-dashboard btn-block text-uppercase"
          onClick={() => {
            history.push(Routes.ADMIN_PROGRAM);
          }}
        >
          Program
        </button>
        <br />
        <button
          className="btn btn-lg btn-dashboard btn-block text-uppercase"
          onClick={() => {
            history.push(Routes.ADMIN_HUMANS);
          }}
        >
          Humans
        </button>
        <br />
        <button
          className="btn btn-lg btn-dashboard btn-block text-uppercase"
          onClick={() => {
            history.push(Routes.ADMIN_USERS);
          }}
        >
          Users
        </button>
      </div>
      <div style={{ display: "inline-block" }}>
        {userName}
        <button className="btn btn-logout" onClick={handleClick}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
