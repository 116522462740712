const request = (
  requestUrl: string,
  requestMethod: string,
  requestBody: object
) => {
  const requestOptions: any = {
    json: true,
    method: requestMethod,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(requestUrl, requestOptions).then((response) => response.json());
};

export const post = (requestUrl: string, requestBody: object) => {
  return request(requestUrl, "POST", requestBody);
};
