import { IS_LOCAL, IS_SANDBOX } from "@afterword/core/utils";

const prodConfig = {
  apiKey: "AIzaSyB3vQIGl7psddAhZwLa-wkOQRomq8dgzC4",
  authDomain: "afterword.com",
  databaseURL: "https://afterword-prod.firebaseio.com",
  projectId: "afterword-prod",
  storageBucket: "afterword-prod.appspot.com",
  messagingSenderId: "331061227156",
  appId: "1:331061227156:web:86b74f87c623b8e3b48e81",
  measurementId: "G-57PDW9DVPQ",
};
const sandboxConfig = {
  apiKey: "AIzaSyBigan0Y4Rjj0eGN2qyxvOaAY-D9fYFikU",
  authDomain: "sandbox.afterword.com",
  databaseURL: "https://afterword-sandbox.firebaseio.com",
  projectId: "afterword-sandbox",
  storageBucket: "afterword-sandbox.appspot.com",
  messagingSenderId: "298623872728",
  appId: "1:298623872728:web:87d3a3e66863dda24eb1ad",
  measurementId: "G-84VMQ0M1PX",
};

const firebaseConfig = IS_LOCAL || IS_SANDBOX ? sandboxConfig : prodConfig;

export default firebaseConfig;
