import React, { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import HumanInfo from "./familyHumanInfo";

import { Typography, Link } from "@material-ui/core";
import { Layout } from "../../../Layout";

const FamilyDashboard = () => {
  const [humanIds, setHumanIds] = useState([] as any);
  const [connectedHumans, setConnectedHumans] = useState({} as any);
  const [memorialFirstName, setMemorialFirstName] = useState();
  const [viewOnly, setViewOnly] = useState(true); //defaults to view only (i.e true)
  const db = firebase.firestore();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const currUser = firebase.auth().currentUser;
      if (currUser) {
        const currUserUID = currUser.uid;
        const user = await db
          .collection("users")
          .doc(String(currUserUID))
          .get();
        let userData = user.data();
        if (userData) {
          console.log(userData);
          setHumanIds(Object.keys(userData.connectedHumans || {}));
          setConnectedHumans(userData.connectedHumans || {});
        }
      }
      setIsLoaded(true);
    };
    fetchUserData();
  }, []);

  return (
    <Layout>
      {isLoaded && (
        <>
          <div>
            {humanIds && humanIds.length > 0
              ? humanIds.map((connectedHumanId: any) => {
                  db.collection("humans")
                    .doc(connectedHumanId)
                    .get()
                    .then((result) => {
                      const memorialData = result.data();
                      if (memorialData) {
                        setMemorialFirstName(memorialData.firstName);
                      }
                    });
                  return (
                    <div>
                      <HumanInfo
                        humanId={connectedHumanId}
                        viewOnly={
                          connectedHumans[connectedHumanId] !== "editor"
                        }
                      />
                    </div>
                  );
                })
              : ""}

            {humanIds && humanIds.length === 0 && (
              <>
                <h2>
                  Thanks for creating your account. Our team will be in touch
                  with you soon.
                </h2>
                <Typography>
                  If you have questions in the meantime, please email us at{" "}
                  <Link target="_blank" href="mailto:planning@afterword.com">
                    planning@afterword.com
                  </Link>
                </Typography>
              </>
            )}
          </div>
        </>
      )}
    </Layout>
  );
};

export default FamilyDashboard;
