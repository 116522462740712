import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getSiteBase } from "@afterword/core/utils";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import uuid from "react-uuid";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { createArrowFunction } from "typescript";
import { API_BASE } from "../../../config/utils";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Input } from "@material-ui/core";
import Quill from "quill";

import { HumanTimeline } from "../components/humanTimeline";

// import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardTimePicker,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  })
);

export enum SaveStatus {
  NEW = "new",
  LOADING = "loading",
  LOADED = "loaded",
  SAVING = "saving",
  SAVED = "saved",
  SAVING_ERROR = "saving_error",
}

const QUILL_OPTIONS = {
  modules: {
    toolbar: [
      // ['bold', 'italic', 'underline', 'strike'],
      ["bold", "italic", "underline"],
      [{ align: [] }],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ header: [3, 4, false] }],
      // ['link', 'image', 'video'],
      ["link", "image"],
      // [{ color: [] }, { background: [] }],

      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  },
};

const HumanBio = ({ humanId, viewOnly }: any) => {
  // Human Bio Details state vars
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [deathDate, setDeathDate] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [pageSlug, setPageSlug] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isIndexedBySearch, setIsIndexedBySearch] = useState(false);

  // Human Memorial state vars
  const { quill: obitQuill, quillRef: obitRef } = useQuill(QUILL_OPTIONS);
  const { quill: inviteQuill, quillRef: inviteRef } = useQuill(QUILL_OPTIONS);

  const [inviteText, setInviteText] = useState(""); //needs to be rich text
  const [obituary, setObituary] = useState(""); //needs to be rich text
  const [memorialDateTime, setMemorialDatetime] = useState("");
  const [memorialDatetimeTz, setMemorialDatetimeTz] = useState("");
  const [memorialMainTimezone, setMemorialMainTimezone] = useState("");
  const [formattedDatetime, setFormattedDatetime] = useState(""); // String: YYYY-MM-dd T HH:mm:ssZ
  const [memorialTimezones, setMemorialTimezones] = useState("");
  const [memorialDisplayedTimezones, setMemorialDisplayedTimezones] = useState(
    [] as Array<string>
  );

  // Shared state vars
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [saveStatus, setSaveStatus] = useState(SaveStatus.NEW);
  const history = useHistory();
  const [imageAsFile, setImageAsFile] = useState("");
  const [customColumnSpacing, setCustomColumnSpacing] = useState("");

  // We want the quill editors loaded before querying Firestore
  // data so we can properly initialize the rich text fields.
  useEffect(() => {
    // Load listeners individually
    if (obitQuill) {
      obitQuill.on("text-change", () => {
        // In case we need to remove the extra line breaks
        // that Quill adds, we can always use this.
        // .replace(/<p><br><\/p>/g, "<p></p>")
        setObituary(obitQuill.root.innerHTML);
      });
    }
    if (inviteQuill) {
      inviteQuill.on("text-change", () => {
        setInviteText(inviteQuill.root.innerHTML);
      });
    }
    if (obitQuill && inviteQuill) {
      fetchAndSetHuman();
    }
  }, [obitQuill, inviteQuill]);

  const fetchAndSetHuman = async () => {
    setSaveStatus(SaveStatus.LOADING);
    const humanData = await db.collection("humans").doc(String(humanId)).get();
    if (!humanData.exists) {
      // creating new human entity
      console.log("No such document!");
      return;
    } else {
      let human: any = humanData.data();

      // Strings
      setMemorialTimezones(human.memorialTimezones || "");
      setCustomColumnSpacing(human.customColumnSpacing || "");

      // Rich text
      // We disable the editors before pasting so the inputs don't steal focus after pasting
      // https://github.com/quilljs/quill/issues/2156#issuecomment-744359973
      if (obitQuill) {
        obitQuill.disable();
        setObituary(human.obituary || "");
        obitQuill.clipboard.dangerouslyPasteHTML(human.obituary || "", "api");
        obitQuill.enable();
      }
      if (inviteQuill) {
        inviteQuill.disable();
        setInviteText(human.inviteText || "");
        inviteQuill.clipboard.dangerouslyPasteHTML(
          human.inviteText || "",
          "api"
        );
        inviteQuill.enable();
      }
      // const delta = quill.clipboard.convert(html);
      // quill.setContents(delta);

      // Objects
      setMemorialDisplayedTimezones(human.memorialDisplayedTimezones || []);

      // Dates
      setMemorialDatetime(timestampToDate(human.memorialDatetime));
      setLastModifiedDate(timestampToDate(human.lastModifiedDate));

      setFormattedDatetime(timestampToDatetime(human.memorialDatetime));
      setMemorialDatetimeTz(
        timestampToMainTimezone(
          human.memorialDatetimeTz,
          human.memorialMainTimezone
        )
      );
      setMemorialMainTimezone(human.memorialMainTimezone || "");

      // Strings
      setFirstName(human.firstName || "");
      setMiddleName(human.middleName || "");
      setLastName(human.lastName || "");
      setImageUrl(human.imageUrl || "");
      setPageSlug(human.pageSlug || "");
      setCustomColumnSpacing(human.customColumnSpacing || "");

      // Dates
      setBirthDate(timestampToDate(human.birthDate));
      setDeathDate(timestampToDate(human.deathDate));

      // Bool
      setIsPublished(!!human.isPublished);
      setSaveStatus(SaveStatus.LOADED);
      setIsIndexedBySearch(!!human.isIndexedBySearch);
    }
  };

  const timestampToDate = (ts: firebase.firestore.Timestamp) => {
    return !ts ? "" : new Date(ts.seconds * 1000).toISOString().substr(0, 10);
  };

  const timestampToDatetime = (ts: firebase.firestore.Timestamp) => {
    return !ts ? "" : new Date(ts.seconds * 1000).toISOString().substr(0, 23);
  };

  const timestampToMainTimezone = (
    UTCts: firebase.firestore.Timestamp,
    mainTimezoneOffset: string
  ) => {
    if (mainTimezoneOffset && UTCts) {
      // timezoneOffset: signed float representing hours of offset
      const timezoneOffset = parseFloat(mainTimezoneOffset);
      // add timezoneOffset in seconds
      const offsetTS = UTCts.seconds + 3600 * timezoneOffset;
      return new Date(offsetTS * 1000).toISOString().substr(0, 23);
    } else {
      // TODO: parse timezone memorialTimezones here
      return "";
    }
  };

  const getHumanFromState = () => {
    // transform date type variables (human bio)
    let birthDateTimestamp = birthDate
      ? firebase.firestore.Timestamp.fromDate(new Date(birthDate))
      : null;
    let deathDateTimestamp = deathDate
      ? firebase.firestore.Timestamp.fromDate(new Date(deathDate))
      : null;

    // transform date type variables (human memorial)
    let memorialDateTimestamp = memorialDateTime
      ? firebase.firestore.Timestamp.fromDate(new Date(memorialDateTime))
      : null;
    let formattedDatetimeTimestamp = formattedDatetime
      ? firebase.firestore.Timestamp.fromDate(new Date(formattedDatetime))
      : null;

    let lastModifiedDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date()
    );

    return {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      birthDate: birthDateTimestamp,
      deathDate: deathDateTimestamp,
      customColumnSpacing: customColumnSpacing,
      isPublished: isPublished,
      imageUrl: imageUrl,
      pageSlug: pageSlug,
      inviteText: inviteText,
      obituary: obituary,
      memorialDatetime: memorialDateTimestamp,
      memorialTimezones: memorialTimezones,
      memorialDatetimeTz: formattedDatetimeTimestamp,
      memorialMainTimezone: memorialMainTimezone,
      memorialDisplayedTimezones: memorialDisplayedTimezones,
      isDraft: false,
      isIndexedBySearch: isIndexedBySearch,
      lastModifiedDate: lastModifiedDateTimestamp,
    };
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    var newHumanRef = db.collection("humans").doc(String(humanId));

    setSaveStatus(SaveStatus.SAVING);
    newHumanRef
      .update(getHumanFromState())
      .then(() => {
        setSaveStatus(SaveStatus.SAVED);
      })
      .catch((err) => {
        console.log("error", "=>", err);
        setSaveStatus(SaveStatus.SAVING_ERROR);
      });
  };

  const cleanPageSlug = (pageSlugDraft: string) => {
    return pageSlugDraft
      .toLowerCase()
      .replace(/[^a-zA-Z\d\-\_:]/g, "-")
      .replace(/\-+/g, "-")
      .replace(/^\-/, "")
      .replace(/\-$/, "");
  };

  /*** Try to automatically set the page slug based on names.***/
  const maybeSetSlug = () => {
    if (pageSlug) {
      return;
    }
    let fullNameSlug = cleanPageSlug(
      [firstName, middleName, lastName].join("-")
    );
    setPageSlug(fullNameSlug);
  };

  const handleImageUpload = async (event: any) => {
    event.preventDefault();
    const image = event.target.files[0];
    console.log(image);
    setImageAsFile(image);
    return await saveImageToFirebase(image);
  };

  const handleMultiImageUpload = async (event: any) => {
    event.preventDefault();
    const imagesList = event.target.files;
    return Object.entries(imagesList).map(async (image: any) => {
      // image[0] = key, image[1] = file
      return await saveImageToFirebase(image[1]);
    });
  };

  const saveImageToFirebase = async (image: any) => {
    var newMetadata = {
      cacheControl: "public,max-age=4000",
    };
    const fileRef = storageRef.child(`humans/${humanId}/` + uuid());
    await fileRef.put(image, newMetadata);
    let newDownloadURL = await fileRef.getDownloadURL();
    console.log(newDownloadURL);
    return String(newDownloadURL);
  };

  /**
   * Converts a firestore.Timestamp to a UTC-formatted Date string.
   * Since we store Dates in UTC and browsers render dates in the
   * user's timezone, we can snip the first 10 characters of the ISO string.
   * @param ts object with signature {seconds: Number, nanoseconds: Number}
   */
  const formatDatetime = (datetimeInput: string, timezoneInput: string) => {
    if (timezoneInput == "") {
      // do not format without timezone
    } else if (datetimeInput == "") {
      // do not format without date time
    } else {
      // 1. get timezone difference
      const inputTimezone = parseFloat(timezoneInput);
      // 2. convert to date object
      var localMemorialDatetime = new Date(datetimeInput);
      // 3. convert to UTC
      // get local timzone
      var localTimezone = localMemorialDatetime.getTimezoneOffset();
      localTimezone = localTimezone / -60;
      // calculate difference: localTimezone - inputTimezone
      const timezoneDiff = localTimezone - inputTimezone;
      // apply difference to UTC time (apply diff in milliseconds)
      const UTCMemorialDatetime = new Date(
        localMemorialDatetime.getTime() + timezoneDiff * 3600000
      );
      // update formattedDatetime variable
      setFormattedDatetime(UTCMemorialDatetime.toISOString());
    }
  };

  return (
    <div
      // style={{ maxWidth: "1280px", margin: "0 auto" }}
      style={{ flexGrow: 1 }}
    >
      <form
        onSubmit={handleSubmit}
        // style={{ maxWidth: "1000px", margin: "0 auto" }}
        // className="p-4"
        // className={classes.root}
        noValidate
        autoComplete="off"
      >
        <fieldset disabled={viewOnly}>
          <Grid container>
            <Grid item xs={12} sm={9}>
              {/* Human Bio Details */}
              <Card style={{ padding: "40px", marginBottom: "40px" }}>
                <CardHeader
                  title="Who are we remembering?"
                  style={{ padding: "0px" }}
                />
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "20px", paddingTop: "20px" }}
                >
                  <Grid item sm={4}>
                    <label>Add a photo of your loved one.</label>
                    <input
                      type="file"
                      onChange={(event) => {
                        let downloadUrl = handleImageUpload(event);
                        downloadUrl.then((result) =>
                          setImageUrl(String(result))
                        );
                      }}
                      style={{ marginTop: "20px", color: "grey" }}
                    />
                    <br />
                    {imageUrl && (
                      <a href={imageUrl} target="_blank">
                        <img src={imageUrl} style={{ maxWidth: "200px" }} />
                      </a>
                    )}
                  </Grid>
                  <Grid item xs={12} sm container spacing={2}>
                    <Grid item sm={12} className="humanFormItem">
                      <Typography
                        style={{ minWidth: "100%" }}
                        gutterBottom
                        variant="subtitle1"
                      >
                        Who are we remembering?
                      </Typography>
                    </Grid>
                    <Grid item sm={4} className="humanFormItem">
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="filled"
                        className="block"
                        disabled={viewOnly}
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        fullWidth={true}
                        style={{ backgroundColor: "white" }}
                      />
                    </Grid>
                    <Grid item sm={4} className="humanFormItem">
                      <TextField
                        id="outlined-basic"
                        label="Middle Name"
                        variant="filled"
                        className="block"
                        disabled={viewOnly}
                        value={middleName}
                        onChange={(event) => setMiddleName(event.target.value)}
                        fullWidth={true}
                        style={{ backgroundColor: "white" }}
                      />
                    </Grid>
                    <Grid item sm={4} className="humanFormItem">
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="filled"
                        className="block"
                        disabled={viewOnly}
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        onBlur={(event) => maybeSetSlug()}
                        fullWidth={true}
                        style={{ backgroundColor: "white" }}
                      />
                    </Grid>
                    <Grid item sm={12} className="humanFormItem">
                      <TextField
                        id="outlined-basic"
                        label="Page Slug"
                        variant="filled"
                        className="block"
                        disabled={viewOnly}
                        value={pageSlug}
                        // pattern="[a-z\-]+"
                        title="Only lowercase letters and hyphens"
                        onChange={(event) =>
                          setPageSlug(cleanPageSlug(event.target.value))
                        }
                        fullWidth={true}
                        style={{ backgroundColor: "white" }}
                      />

                      <a
                        className="text-blue-400"
                        href={`${getSiteBase()}/stories/${pageSlug}`}
                        target="_blank"
                      >
                        {" "}
                        view live page
                      </a>
                    </Grid>
                    {/* <input
                      type="text"
                      className="block"
                      value={pageSlug}
                      pattern="[a-z\-]+"
                      title="Only lowercase letters and hyphens"
                      onChange={(event) => setPageSlug(event.target.value)}
                  /> */}
                    {/* </div> */}

                    <Grid item sm={6} className="humanFormItem">
                      <label>Date of birth:</label>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date picker inline"
                      value={birthDate}
                      onChange={(event: any) => setBirthDate(event.target.value)}
                      KeyboardButtonProps={{'aria-label': 'change date',}}
                          />
                  </MuiPickersUtilsProvider> */}

                      <input
                        type="date"
                        disabled={viewOnly}
                        className="block humanFormDate"
                        value={birthDate}
                        onChange={(event) => setBirthDate(event.target.value)}
                        style={{ backgroundColor: "#E8E8E8" }}
                      />
                    </Grid>
                    <Grid item sm={6} className="humanFormItem">
                      <label>Date of death:</label>
                      <input
                        type="date"
                        disabled={viewOnly}
                        className="block humanFormDate"
                        value={deathDate}
                        onChange={(event) => setDeathDate(event.target.value)}
                        style={{
                          color: "grey",
                          borderColor: "#C4C4C4",
                          backgroundColor: "#E8E8E8",
                        }}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <label>Published?</label>
                      <Checkbox
                        checked={isPublished}
                        color="default"
                        onChange={(_) => setIsPublished(!isPublished)}
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} className="humanFormItem">
                      <TextField
                        id="outlined-basic"
                        label="Custom Layout"
                        variant="filled"
                        className="block"
                        inputProps={{ pattern: "[0-9]fr [0-9]fr" }}
                        title="e.g. '1fr 3fr'"
                        value={customColumnSpacing}
                        onChange={(event) =>
                          setCustomColumnSpacing(event.target.value)
                        }
                        fullWidth={true}
                        style={{ backgroundColor: "white" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              {/* Human Memorial Details */}
              <Card style={{ padding: "40px" }}>
                <CardHeader
                  title="Memorial Details"
                  style={{ padding: "0px" }}
                />
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "20px", paddingTop: "20px" }}
                >
                  <Grid item sm={4}>
                    <div>
                      <label>Memorial Date:</label>
                      <input
                        type="date"
                        className="block"
                        value={memorialDateTime}
                        onChange={(event) => {
                          console.log(event.target.value);
                          setMemorialDatetime(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4}>
                    <div>
                      <label>Memorial Timezone</label>
                      <input
                        type="text"
                        className="block"
                        value={memorialTimezones}
                        onChange={(event) => {
                          let newTimezone = event.target.value;
                          setMemorialTimezones(newTimezone);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4}></Grid>
                  {/* <Grid item sm={4} className="humanFormItem">
                    <label>Memorial Date and Time</label>
                    <input
                      type="datetime-local"
                      className="block humanFormDate"
                      value={memorialDatetimeTz}
                      onChange={(event) => {
                        setMemorialDatetimeTz(event.target.value);
                        formatDatetime(
                          event.target.value,
                          memorialMainTimezone
                        );
                      }}
                      style={{ backgroundColor: "#E8E8E8" }}
                    />
                  </Grid>
                  <Grid item sm={4} className="humanFormItem">
                    <label>Memorial Timezone</label>
                    <br />
                    <select
                      name="timezone_offset"
                      id="timezone-offset"
                      className="span5 humanFormDate"
                      onChange={(event) => {
                        setMemorialMainTimezone(event.target.value);
                        formatDatetime(memorialDatetimeTz, event.target.value);
                      }}
                      value={memorialMainTimezone}
                      style={{ maxWidth: "200px", backgroundColor: "#E8E8E8" }}
                    >
                      <option value="">None</option>
                      <option value="-8.00">
                        (GMT -8:00) Pacific Time (US &amp; Canada)
                      </option>
                      <option value="-7.00">
                        (GMT -7:00) Mountain Time (US &amp; Canada)
                      </option>
                      <option value="-6.00">
                        (GMT -6:00) Central Time (US &amp; Canada), Mexico City
                      </option>
                      <option value="-5.00">
                        (GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima
                      </option>
                    </select>
                    {(memorialDisplayedTimezones || []).map((timezone, idx) => {
                      return (
                        <div>
                          <select
                            value={timezone}
                            onChange={(event) => {
                              let newTimezone =
                                memorialDisplayedTimezones.concat([]);
                              newTimezone[idx] = event.target.value;
                              setMemorialDisplayedTimezones(newTimezone);
                            }}
                            className="humanFormDate"
                            style={{
                              backgroundColor: "#E8E8E8",
                              display: "inline",
                            }}
                          >
                            <option value="">None</option>
                            <option value="-8.00">
                              (GMT -8:00) Pacific Time (US &amp; Canada)
                            </option>
                            <option value="-7.00">
                              (GMT -7:00) Mountain Time (US &amp; Canada)
                            </option>
                            <option value="-6.00">
                              (GMT -6:00) Central Time (US &amp; Canada), Mexico
                              City
                            </option>
                            <option value="-5.00">
                              (GMT -5:00) Eastern Time (US &amp; Canada),
                              Bogota, Lima
                            </option>
                          </select>
                          <button
                            type="button"
                            className="btn btn-grey text-xs"
                            onClick={() => {
                              let shouldDelete =
                                window.confirm("Are you sure?");
                              if (!shouldDelete) {
                                return;
                              }
                              let newTimezone =
                                memorialDisplayedTimezones.concat([]);
                              newTimezone.splice(idx, 1);
                              setMemorialDisplayedTimezones(newTimezone);
                            }}
                            style={{ display: "inline" }}
                          >
                            remove timezone
                          </button>
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid item sm={4}>
                    <label>Additional Timezones</label>
                    <br />
                    <button
                      type="button"
                      className="btn btn-grey"
                      onClick={() => {
                        let newTimezone = memorialDisplayedTimezones.concat(
                          {} as ConcatArray<string>
                        );
                        setMemorialDisplayedTimezones(newTimezone);
                      }}
                    >
                      add timezone
                    </button>
                  </Grid> */}
                </Grid>
              </Card>
              <br />
              <Card style={{ padding: "40px", marginBottom: "40px" }}>
                <CardHeader title="Invite Message" style={{ padding: "0px" }} />
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "20px", paddingTop: "20px" }}
                >
                  <Grid item sm={12} className="humanFormItem">
                    <div className="mb-4">
                      <label>Invite Text:</label>
                      {/* <input
                        type="text"
                        className="block"
                        value={inviteText}
                        onChange={(event) => setInviteText(event.target.value)}
                      /> */}
                      <div ref={inviteRef} />
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: "40px", marginBottom: "40px" }}>
                <CardHeader title="Obituary" style={{ padding: "0px" }} />
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "20px", paddingTop: "20px" }}
                >
                  <Grid item sm={12} className="mb-4 humanFormItem">
                    <div className="mb-4">
                      <div ref={obitRef} />
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: "40px", marginBottom: "40px" }}>
                <CardHeader
                  title="Page Visibility"
                  style={{ padding: "0px" }}
                />
                <Grid item sm={6}>
                  <label>Show on Google?</label>
                  <Checkbox
                    checked={isIndexedBySearch}
                    color="default"
                    onChange={(_) => setIsIndexedBySearch(!isIndexedBySearch)}
                    inputProps={{
                      "aria-label": "checkbox with default color",
                    }}
                  />
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="save-container" style={{ textAlign: "center" }}>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
                <div className="text-gray-500 text-md">{saveStatus}</div>
                {saveStatus === SaveStatus.SAVING_ERROR && (
                  <div className="text-red-500 text-lg">
                    Error: check console
                  </div>
                )}
              </div>
              <HumanTimeline />
            </Grid>
          </Grid>
        </fieldset>
      </form>
    </div>
  );
};

export default HumanBio;
