import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/auth";
import "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { AuthFlowContainer } from "./AuthFlow";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Routes } from "../../../config/constants";
import { API_BASE } from "../../../config/utils";
import EmailIcon from "@material-ui/icons/Email";
import * as server from "../../../services/server";

const EmailMeALink = () => {
  const [showInput, setShowInput] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const history = useHistory();

  const db = firebase.firestore();

  useEffect(() => {
    var email = window.localStorage.getItem("emailForSignIn");
    if (email) {
      setUserEmail(email);
    }
  }, []);

  const handleSubmit = (event: any) => {
    setIsSubmitting(true);
    event.preventDefault();
    return server
      .post(`${API_BASE}/forms/emailLoginLink`, {
        email: userEmail.toLowerCase(),
        firstName: "",
      })
      .then(async (data: any) => {
        console.log("data", data);
        setShowInput(false);
        setMessage(
          "Thanks. If this email has an Afterword account, we'll send a login link shortly. Note: If you use Gmail, the email may appear in your Promotions category."
        );
        return data;
      })
      .catch((error: any) => {
        console.log("Error occured while sending sign in-link:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <AuthFlowContainer
      cardTitle="Email me a login link"
      cardFooter={
        <span>
          By continuing, you agree to our{" "}
          <Link target="_blank" href="//afterword.com/terms">
            Terms
          </Link>{" "}
          &{" "}
          <Link target="_blank" href="//afterword.com/privacy">
            Privacy Policy
          </Link>
        </span>
      }
    >
      {showInput && (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography>
                We'll send you an email with a link to login to your account.
                The links only work once and expire after a few hours.
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                required
                id="standard-basic"
                label="Email Address"
                type="email"
                name="email"
                variant="standard"
                className="block"
                value={userEmail}
                placeholder="your.email@domain.com"
                onChange={(event) => setUserEmail(event.target.value)}
                fullWidth={true}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Email Me"}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {message && (
        <>
          <Grid item>
            <Grid item>
              <Typography variant="caption">{message}</Typography>
            </Grid>
          </Grid>
        </>
      )}
    </AuthFlowContainer>
  );
};

export default EmailMeALink;
