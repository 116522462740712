import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";

import { AuthFlowContainer } from "../../Auth/components/AuthFlow";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";

import Checkbox from "@material-ui/core/Checkbox";
import { Routes } from "../../../config/constants";

const TOS = () => {
  console.log("in tos");
  const [signed, setSigned] = useState(false);
  const db = firebase.firestore();
  const history = useHistory();
  const { setTosSigned } = useContext(AuthContext);

  const handleSubmit = (event: any) => {
    event?.preventDefault();
    // update tos status for user
    var user = firebase.auth().currentUser;
    if (user) {
      var userRef = db.collection("users").doc(String(user.uid));
      userRef.get().then((response) => {
        const userData = response.data();
        if (userData) {
          userRef.update({
            tosSigned: firebase.firestore.Timestamp.fromDate(new Date()),
          });
          setTosSigned(firebase.firestore.Timestamp.fromDate(new Date()));
          history.push(Routes.HOME_PLANNER);
        }
      });
    } else {
      console.log("Error signing in user. Please request new link.");
    }
  };

  return (
    <AuthFlowContainer cardTitle="Complete your account">
      <form onSubmit={() => {}}>
        <Grid container direction="column" justify="space-between" spacing={3}>
          <Grid item>
            <TextField
              autoFocus
              required
              id="standard-basic"
              label="First Name"
              type="input"
              name="firstName"
              variant="standard"
              className="block"
              // value={userEmail}
              // placeholder="your.email@domain.com"
              // onChange={(event) => setUserEmail(event.target.value)}
              fullWidth={true}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="standard-basic"
              label="Last Name"
              type="input"
              name="lastName"
              variant="standard"
              className="block"
              // value={userEmail}
              // placeholder="your.email@domain.com"
              // onChange={(event) => setUserEmail(event.target.value)}
              fullWidth={true}
            />
            <Grid container alignItems="center" justify="center">
              <Checkbox
                checked={signed}
                required
                color="default"
                onChange={(_) => setSigned(!signed)}
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
              <label>
                <Typography style={{ paddingLeft: "10px" }}>
                  I agree to the{" "}
                  <Link target="_blank" href="https://afterword.com/terms">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link target="_blank" href="https://afterword.com/privacy">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </label>
            </Grid>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" type="submit">
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
    </AuthFlowContainer>
  );
};

export default TOS;
