import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import "quill/dist/quill.snow.css";
import HumanBio from "./humanBio";
import HumanMedia from "./humanMedia";
import HumanPostService from "./humanPostService";
import HumanAdmin from "./humanAdmin";
// Material UI for tabs
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Routes } from "../../../config/constants";

interface RecordingBlob {
  url: string;
  poster: string;
  title: string;
}

const HumanInfo = () => {
  const { humanId, tabName } = useParams();
  const history = useHistory();
  const [recordingBlobs, setRecordingBlobs] = useState(
    [] as Array<RecordingBlob>
  );

  // Material UI Tabs
  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#F7F7F7",
    },
  }));

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    let mapping: any = {
      bio: 0,
      media: 1,
      post: 2,
      admin: 3,
    };
    if (tabName) {
      setValue(mapping[tabName] || 0);
    }
  }, []);

  return (
    <div>
      <h1>
        <a
          className="view-link"
          href="#"
          onClick={() => {
            history.push(Routes.ADMIN_HUMANS);
          }}
        >
          ← Humans
        </a>
      </h1>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label="Biography"
              {...a11yProps(0)}
              component={Link}
              to={`${Routes.ADMIN_HUMANS}/${humanId}/bio`}
            />
            <Tab
              label="Media"
              {...a11yProps(1)}
              component={Link}
              to={`${Routes.ADMIN_HUMANS}/${humanId}/media`}
            />
            <Tab
              label="Post Service"
              {...a11yProps(2)}
              component={Link}
              to={`${Routes.ADMIN_HUMANS}/${humanId}/post`}
            />
            <Tab
              label="Admin"
              {...a11yProps(3)}
              component={Link}
              to={`${Routes.ADMIN_HUMANS}/${humanId}/admin`}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <HumanBio humanId={humanId} viewOnly={false} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HumanMedia humanId={humanId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <HumanPostService humanId={humanId} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <HumanAdmin humanId={humanId} />
        </TabPanel>
      </div>
    </div>
  );
};

export default HumanInfo;
