import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import { promises } from "dns";
import { Routes } from "../../../config/constants";

const db = firebase.firestore();

const Users = () => {
  const history = useHistory();
  const [usersList, setUsersList] = useState([] as any);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await db.collection("users").get();
      setUsersList(usersCollection.docs);
    };
    fetchUsers();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <button
        onClick={() => {
          history.push(Routes.ADMIN_USERS + "/create_new");
        }}
        className="btn btn-createHuman"
      >
        Create New User Account
      </button>
      <br />
      <br />
      <h2>
        <strong>Current Users</strong>
      </h2>
      <ul>
        {usersList.map((doc: any) => {
          let data = doc.data();
          if (!data.isDraft) {
            return (
              <li key={doc.id}>
                <button
                  onClick={() => {
                    history.push(Routes.ADMIN_USERS + "/edit/" + doc.id);
                  }}
                >
                  {data.email} — {data.firstName} {data.lastName}
                </button>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default Users;
