export const IS_LOCAL = process.env.NODE_ENV === "development";

export const IS_SANDBOX =
  IS_LOCAL ||
  (typeof window === "object" &&
    (window.location.hostname.startsWith("sandbox") ||
      window.location.hostname.startsWith("afterword-sbox")));

export function getSiteBase() {
  if (IS_LOCAL) {
    return "http://localhost:8000";
  }
  return IS_SANDBOX ? "https://sandbox.afterword.com" : "https://afterword.com";
}

export function getAppBase() {
  if (IS_LOCAL) {
    return "http://localhost:3000";
  }
  return IS_SANDBOX
    ? "https://sandbox-app.afterword.com"
    : "https://plan.afterword.com";
}

// TODO: Setup local emulator as an option
export function getApiBase() {
  // return IS_SANDBOX ? "https://afterword-sbox-app.web.app" : "https://plan.afterword.co";
  if (IS_LOCAL) {
    return "http://localhost:5001/afterword-sandbox/us-central1";
  }
  return IS_SANDBOX
    ? "https://us-central1-afterword-sandbox.cloudfunctions.net"
    : "https://us-central1-afterword-prod.cloudfunctions.net";
}

export const API_BASE = getApiBase();
