// Interfaces & Enums for handling programs, steps, and commands.
export enum ProgramCommandType {
  OBS = "OBS",
  ZOOM = "ZOOM",
  GMEET = "GMEET",
  WEBEX = "WEBEX",
  NONE = "",
}

// Feedback from Steph
// Abstract video logic from Zoom so we can plugin Hangouts, Teams, etc.

export interface Program {
  id: string;
  name: string;
  steps: Array<ProgramStep>;
  programStep: number;
  config?: ProgramConfig;
  // acl: Array<AclRule>,
}

export interface ProgramConfig {
  startTime?: number; // timestamp in UTC
  obsHost?: string;
  zoomHost?: string;
  zoomUrl?: string;
  zoomId?: string;
  zoomPasscode?: string;
}

// In early stages, a step will only have one command
export interface ProgramStep {
  name: string;
  commands: Array<ProgramCommand>;
  shouldAutoAdvance?: number; // if defined, defines ms before advancing to next command
}

export interface ZoomCommandSettings {
  speakerId?: number;
  speakerName?: string;
  shouldUnmute?: boolean;
  shouldSpotlight?: boolean; // TODO: make this replace vs add
  shouldMuteOthers?: boolean;
  shouldStartRecording?: boolean; // TODO: make this record to local instead of cloud
  // shouldSetGalleryMode: boolean,
}

export interface OBSCommandSettings {
  sceneName?: string;
  volumeLevel?: number;
  shouldMuteMic?: boolean;
  // sceneItems: Array<string>,  TODO: do we need this or care?
}

export interface ProgramCommandSettings
  extends ZoomCommandSettings,
    OBSCommandSettings {}

export interface ProgramCommand {
  type: ProgramCommandType;
  settings: ProgramCommandSettings;
  shouldAutoAdvance?: number; // if defined, defines ms before advancing to next command
}
