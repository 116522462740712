import React, { useEffect, useState } from "react";
import firebase from "./firebase";

// Import OBS-websocket
import OBSWebSocket from "obs-websocket-js";
import { register } from "./serviceWorker";

type ContextProps = {
  obs: OBSWebSocket;
  isConnected: boolean;
  currentScene: string; // OBSWebSocket.Scene.name,
  scenes: Array<OBSWebSocket.Scene>;
  sources: Array<OBSWebSocket.SceneItem>;
  setHost: any;
  connect: any;
  disconnect: any;
};

export const ObsContext = React.createContext<Partial<ContextProps>>({});

export const ObsProvider = ({ children }: any) => {
  const [obs, setObs] = useState(new OBSWebSocket() as OBSWebSocket);
  const [isConnected, setIsConnected] = useState(false);
  const [isStudioMode, setIsStudioMode] = useState(false);
  const [host, setHost] = useState("localhost:4444");

  const [currentScene, setCurrentScene] = useState("");
  const [currentPreviewScene, setCurrentPreviewScene] = useState("");

  const [scenes, setScenes] = useState([]);
  const [sources, setSources] = useState([]);

  let password = "123";

  const SHOW_PREVIEW = false;

  useEffect(() => {
    // setObs(new OBSWebSocket());
    console.log("obs initiatied");
    registerEventListeners();
  }, []);

  function registerEventListeners() {
    // OBS events
    obs.on("ConnectionClosed", () => {
      // connected = false;
      setIsConnected(false);
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      ); // Remove the hash
      console.log("Connection closed");
    });

    obs.on("AuthenticationSuccess", async () => {
      setIsConnected(true);
      // document.location.hash = host; // For easy bookmarking
      // const version = (await sendCommand('GetVersion')).obsWebsocketVersion || '';
      // console.log('OBS-websocket version:', version);
      // if(compareVersions(version, OBS_WEBSOCKET_LATEST_VERSION) < 0) {
      //   alert('You are running an outdated OBS-websocket (version ' + version + '), please upgrade to the latest version for full compatibility.');
      // }
      // await sendCommand("SetHeartbeat", { enable: true });
      await getStudioMode();
      await updateScenes();
      await getSources();
      // await getScreenshot();
      // if (SHOW_PREVIEW) {
    });

    obs.on("AuthenticationFailure", async () => {
      password = prompt("Please enter your password:", password) + "";
      if (password === null) {
        setIsConnected(false);
        password = "";
      } else {
        await connect();
      }
    });

    // Heartbeat
    // obs.on('Heartbeat', data => {
    //   heartbeat = data;
    // });

    // Scenes
    obs.on("SwitchScenes", async (data) => {
      console.log(`New Active Scene: ${data["scene-name"]}`);
      await updateScenes();
    });

    obs.on("SceneItemAdded", async (data) => {
      console.log(data);
      console.log(`New scene item: ${data["scene-name"]}`);
      await updateScenes();
    });

    obs.on("SourceCreated", async (data) => {
      console.log(data);
      console.log(`New source created: ${data["sourceName"]}`);
      await getSources();
    });

    // obs.on('Error', err => {
    //   console.error('Socket error:', err);
    // });

    obs.on("StudioModeSwitched", async (data) => {
      console.log(`Studio Mode: ${data["new-state"]}`);
      setIsStudioMode(data["new-state"]);
      if (!isStudioMode) {
        setCurrentPreviewScene("");
      } else {
        await updateScenes();
      }
    });

    obs.on("PreviewSceneChanged", async (data) => {
      console.log(`New Preview Scene: ${data["scene-name"]}`);
      await updateScenes();
    });
  }

  async function connect() {
    let secure = window.location.protocol === "https:" || host.endsWith(":443");
    if (host.indexOf("://") !== -1) {
      let url = new URL(host);
      secure = url.protocol === "wss:" || url.protocol === "https:";
      let newHost =
        url.hostname + ":" + (url.port ? url.port : secure ? 443 : 80);
      console.log(`new host: ${newHost}`);
      setHost(newHost);
    }
    console.log(
      "Connecting to:",
      host,
      "- secure:",
      secure,
      "- using password:",
      password
    );
    if (isConnected) {
      await disconnect();
      setIsConnected(false);
    }
    try {
      return await obs.connect({ address: host, password, secure });
    } catch (e) {
      console.log(e);
      return e;
      //   errorMessage = e.description;
    }
  }

  async function sendCommand(command: any, params?: Object) {
    try {
      return await obs.send(command, params || {});
    } catch (e) {
      console.log("Error sending command", command, " - error is:", e);
      return {};
    }
  }

  // async function getScreenshot() {
  //   console.log("get screenshot for " + currentScene);
  //   if (currentScene) {
  //     let data = await sendCommand("TakeSourceScreenshot", {
  //       sourceName: currentScene,
  //       embedPictureFormat: "jpeg",
  //       width: 960,
  //       height: 540,
  //     });
  //     console.log(data);
  //     if (data & data.img) {
  //       // setPreviewImg(data.img);
  //     }
  //   }

  //   // if (isStudioMode) {
  //   //   let data = await sendCommand('TakeSourceScreenshot', { sourceName: currentPreviewScene, embedPictureFormat: 'jpeg', width: 960, height: 540 });
  //   //   if (data && data.img) {
  //   //     document.querySelector('#preview').src = data.img;
  //   //     document.querySelector('#preview').classList.remove('is-hidden');
  //   //   }
  //   // }
  //   setTimeout(getScreenshot, 2000);
  // }

  async function getStudioMode() {
    let data = await sendCommand("GetStudioModeStatus");
    setIsStudioMode((data && data.studioMode) || false);
  }

  async function updateScenes() {
    let data = await sendCommand("GetSceneList");
    console.log(data);
    setCurrentScene(data.currentScene);
    setScenes(
      data.scenes.filter((i: any) => {
        return i.name.indexOf("(hidden)") === -1;
      })
    ); // Skip hidden scenes
    if (isStudioMode) {
      obs
        .send("GetPreviewScene")
        .then((data) => setCurrentPreviewScene(data.name))
        .catch((_) => {
          // Switching off studio mode calls SwitchScenes, which will trigger this
          // before the socket has recieved confirmation of disabled studio mode.
        });
    }
  }

  async function getSources() {
    let data = await sendCommand("GetSourcesList");
    setSources(data.sources);
  }

  async function disconnect() {
    console.log("disconnect");
    await obs.disconnect();
    setIsConnected(false);
    // errorMessage = 'Disconnected';
  }

  async function hostkey(event: any) {
    if (event.key !== "Enter") return;
    await connect();
    event.preventDefault();
  }

  return (
    <ObsContext.Provider
      value={{
        obs,
        isConnected,
        currentScene,
        scenes,
        sources,
        setHost,
        connect,
        disconnect,
      }}
    >
      {children}
    </ObsContext.Provider>
  );
};
