import React from "react";

import Link from "@material-ui/core/Link";
import { Layout } from "../../../Layout";

const ContractTerms = () => {
  return (
    <>
      <Layout>
        <h1>Terms & Conditions</h1>
        <p>Last Updated: 2021-06-01</p>
        <h2>OVERVIEW</h2>
        <p>
          Thank you for choosing Afterword Inc. (“Afterword”) to provide
          services (“Services”) in connection with your upcoming memorial
          service (“Memorial Service”). This document sets out the terms and
          conditions on which Afterword will provide these Services to you
          (“You” or “Your”). If you have any questions, please email us at{" "}
          <Link href="mailto:contact@afterword.com">contact@afterword.com</Link>
          . The terms of service for using our website can be found at{" "}
          <Link target="_blank" href="https://afterword.com/terms">
            afterword.com/terms
          </Link>
          .
        </p>

        <h2>CHANGES TO TERMS OF SERVICE</h2>
        <p>
          You can review the most current version of the Terms of Service at any
          time at this page.
        </p>
        <br />
        <p>
          We reserve the right, at our sole discretion, to update, change or
          replace any part of these Terms of Service by posting updates and
          changes to our website. It is your responsibility to check our website
          periodically for changes. Your continued use of or access to our
          website or the Service following the posting of any changes to these
          Terms of Service constitutes acceptance of those changes.
        </p>
        <h2>VENUE, FURNISHING MATERIALS, SERVICES, &amp; RELEASES</h2>
        <p>
          For In-Person Services, You will verify with the venue that Afterword
          can have access at least one hour prior to the start of the Memorial
          Service. Afterword will then follow up with the venue to confirm
          logistics. The venue for the Memorial Service will need to have
          internet access via Wi-Fi that Afterword’s representatives can use.
          Afterword will supply everything else required for the delivery of the
          Services unless other arrangements are made.
        </p>
        <h2>OWNERSHIP AND DELIVERY OF MEDIA</h2>
        <p>
          You will own all right, title and interest in and to the media
          (“Media”) created by the Services, including all copyrights. Your
          Media will be made available to You 3-5 business days after the
          completion of the Memorial Service.
        </p>
        <br />
        <p>
          You hereby grant Afterword a perpetual non-exclusive, non-transferable
          license to use the Media for no additional consideration in connection
          with promotional and marketing activities of Afterword, provided the
          promotional and marketing activities do not display any individuals or
          personal information associated with the Memorial Service.
        </p>
        <h2>BUSINESS RELATIONSHIP</h2>
        <p>
          It is understood and agreed that Afterword’s status under this
          Agreement is that of an independent contractor and that all persons
          engaged by Afterword in performing its obligations shall not be deemed
          to be Your employees.
        </p>
        <h2>AFTERWORD REPRESENTATIONS &amp; WARRANTIES</h2>
        <p>
          Afterword represents and warrants that it has authority to enter into
          this Agreement and to perform its obligations hereunder and will
          comply with all applicable Federal, State and local laws, ordinances
          and regulations in connection with the delivery of the Services.
        </p>
        <h2>PAYMENT</h2>
        <p>
          You understand and agree that Afterword will authorize Your credit
          card 48 hours before any Services are performed and will process
          payment on Your credit card after the Services are completed.
        </p>
        <h2>ASSIGNMENT</h2>
        <p>
          This Agreement may not be assigned by either party without the written
          consent of the other.
        </p>
        <h2>FORCE MAJEURE</h2>
        <p>
          There may be reasons beyond the control of either You or Afterword
          that prevents the Services from being provided. This is known as a
          “force majeure”. If that happens, neither You nor Afterword will be
          liable for failure or delay to perform obligations under this
          Agreement which have become practicably impossible because of
          circumstances beyond the reasonable control of You or Afterword, as
          the case may be. Such circumstances include, without limitation:
          natural disasters or acts of God; acts of terrorism; labor disputes or
          stoppages; war; government acts or orders; epidemics, pandemics or
          outbreak of communicable disease; quarantines; national or regional
          emergencies; disruptions to electrical or internet services; or any
          other cause, whether similar in kind to the foregoing or otherwise,
          beyond the control the reasonable control of You or Afterword, as the
          case may be.
        </p>
        <br />
        <p>
          If You or Afterword will not be able to proceed with the Services or
          there will need to be a delay, You or Afterword must give written
          notice no later than one business day following the force majeure
          event commencing. This notice will need to describe the force majeure
          event and the actions taken to minimize the impact of it. Once this is
          done, the relevant time periods under this Agreement affected by the
          force majeure event will be suspended from running for the duration of
          the force majeure event. You and Afterword hereby agree, when
          feasible, not to cancel but to reschedule the relevant obligations and
          deliverables for mutually agreed dates as soon as practicable after
          the force majeure condition ceases to exist.
        </p>
        <h2>TIME OF THE ESSENCE</h2>
        <p>Time is of the essence of this Agreement.</p>
        <h2>NO WAIVER</h2>
        <p>
          Failure of by You or Afterword to exercise any rights under this
          Agreement will not constitute a waiver of those rights.
        </p>
        <h2>ENFORCEABILITY</h2>
        <p>
          If one or more of the provisions of this Agreement shall be held
          unenforceable, it shall not affect the enforceability of the other
          provisions.
        </p>
        <h2>APPLICABLE LAW</h2>
        <p>
          This Agreement shall be construed in accordance with the laws of the
          State of New York (without regard to conflicts of laws principles),
          and the obligations, rights and remedies of the parties hereunder
          shall be determined in accordance with such laws.
        </p>
        <h2>EXECUTION</h2>
        <p>
          This Agreement may be executed in any number of counterparts, each of
          which will be deemed to be an original and all of which taken together
          will be deemed to constitute one and the same instrument. Delivery of
          an executed signature page to this Agreement by any party by
          electronic transmission will be as effective as delivery of a manually
          executed copy of this Agreement by such party.
        </p>
        <h2>SERVICES PROVIDED</h2>
        <p>
          Services provided vary by the package purchased. A full breakdown of
          our services is available at{" "}
          <Link target="_blank" href="https://afterword.com/services/">
            afterword.com/services/
          </Link>
          .
        </p>
      </Layout>
    </>
  );
};

export default ContractTerms;
