import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { getEnvironment } from "@afterword/core/utils";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./AuthProvider";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#657075",
    },
    secondary: {
      main: "#B99891",
    },
  },
  typography: {
    // poster: {
    //   color: 'red',
    // },
    // https://next.material-ui.com/customization/typography/#adding-amp-disabling-variants
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

Sentry.init({
  dsn: "https://8fa71e9cc1ea45d3a83442661b6842dd@o741469.ingest.sentry.io/5786694",
  integrations: [new Integrations.BrowserTracing()],
  environment: getEnvironment(),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
