import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { AuthContext } from "./AuthProvider";
import { Routes } from "./config/constants";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Georgia",
    fontSize: "28px",
    fontWeight: "normal",
    letterSpacing: "1px",
  },
  link: {
    marginRight: "20px",
    "& a": {
      color: "#FFFFFF",
    },
  },
}));

export const Header = ({}: any) => {
  const history = useHistory();
  const { userValues, isAdmin } = useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tabValue, setTabValue] = React.useState(0);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
        <Typography className={classes.title}>Afterword</Typography>
        {/* <Tabs value={tabValue} onChange={handleChange} centered aria-label="simple tabs example">
                <Tab label="Home" {...a11yProps(0)} />
            </Tabs> */}
        {isAdmin && (
          <>
            <Typography className={classes.link}>
              <Link href={Routes.ADMIN_HUMANS}>Humans</Link>
            </Typography>
            <Typography className={classes.link}>
              <Link href={Routes.ADMIN_USERS}>Users</Link>
            </Typography>
          </>
        )}
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          {userValues && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem> */}
              {isAdmin && (
                <MenuItem
                  onClick={() => {
                    history.push(Routes.ADMIN_HOME);
                  }}
                >
                  Admin Panel
                </MenuItem>
              )}
              <MenuItem>Logged in as {userValues.email}</MenuItem>
            </Menu>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export const Layout = ({ children }: any) => {
  return (
    <>
      <Header></Header>
      <div style={{ maxWidth: "1400px", margin: "0 auto", padding: "10px" }}>
        {children}
      </div>
    </>
  );
};
