import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { Users, CreateUser } from "../components";
import AdminRoute from "../../../AdminRoute";
import { Routes } from "../../../config/constants";

export const UsersRoutes = () => {
  return (
    <Switch>
      <AdminRoute exact path={Routes.ADMIN_USERS} component={Users} />
      <AdminRoute
        exact
        path={Routes.ADMIN_USERS + "/create_new"}
        component={CreateUser}
      />
      <AdminRoute
        exact
        path={Routes.ADMIN_USERS + "/edit/:userId"}
        component={CreateUser}
      />
    </Switch>
  );
};
