import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { exec } from "child_process";

const ZoomOauth = () => {
  // Parse data from URL (sent from express server)
  const fullURL = String(window.location.href);
  const access_token = fullURL.split("?access_token=")[1].split("&")[0];
  // TODO: expires in is not being set correctly, but it is always an hour in seconds from zoom
  const expires_in = fullURL.split("expiration_date=")[1];
  var now = new Date();
  var expiration_date_s = now.getTime() / 1000 + 3600;
  var expiration_date = expiration_date_s.toString();

  // Set local storage
  localStorage.setItem("accessToken", String(access_token));
  localStorage.setItem("accessTokenExpiration", expiration_date);

  return <div>Oauth Flow Complete</div>;
};

export default ZoomOauth;
