import React from "react";
import { Route, Switch } from "react-router-dom";
import { Program, Header } from "../components";
import { ObsProvider } from "../../../OBSProvider";
import AdminRoute from "../../../AdminRoute";

export const ProgramRoutes = () => {
  return (
    <ObsProvider>
      <Header />
      <Switch>
        <AdminRoute exact path="/program" component={Program} />
        <Route exact path="/program/:pId" component={Program} />
      </Switch>
    </ObsProvider>
  );
};
