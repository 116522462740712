import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import uuid from "react-uuid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Input } from "@material-ui/core";

import { HumanTimeline } from "../components/humanTimeline";
import { Routes } from "../../../config/constants";
import moment from "moment-timezone";
import {
  HumanContext,
  SaveStatus,
  SaveIndicator,
} from "../../../HumanProvider";

export const convertDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const convertTimestampToUtcDate = (
  firestoreTimestamp: firebase.firestore.Timestamp
) => {
  if (!firestoreTimestamp) return "";
  return convertDateToUTC(new Date(firestoreTimestamp.seconds * 1000));
};

const HumanConfirm = ({ userId }: any) => {
  const { isLoaded, setHumanModel, humanModel, updateFirestore, saveStatus } =
    useContext(HumanContext);
  const history = useHistory();
  const [signedTerms, setSignedTerms] = useState(false);

  useEffect(() => {
    if (isLoaded && !!humanModel.signedContractsTerms[userId]) {
      setSignedTerms(true);
    }
  }, [isLoaded]);

  const checkSigning = () => {
    if (isLoaded && !signedTerms && !!humanModel.signedContractsTerms[userId]) {
      setSignedTerms(true);
    }
  };

  return (
    <form>
      <Grid container style={{ padding: "0" }}>
        <Grid item xs={12} sm={12} md={8} style={{ margin: "0 auto" }}>
          <Card style={{ padding: "40px", marginBottom: "40px" }}>
            <CardHeader
              title={signedTerms ? "Confirmed" : "Confirm Service"}
              style={{ padding: "0px" }}
            />
            {isLoaded && (
              <Grid
                container
                spacing={2}
                direction="column"
                style={{ paddingLeft: "10px", paddingTop: "20px" }}
              >
                <div style={{ maxWidth: "100%" }}>
                  Memorial for{" "}
                  {[
                    humanModel.firstName,
                    humanModel.middleName,
                    humanModel.lastName,
                  ].join(" ")}
                  <br />
                  Date:{" "}
                  {humanModel.memorialDatetime
                    ? moment(
                        convertTimestampToUtcDate(humanModel.memorialDatetime)
                      ).format("dddd, MMM Do, YYYY")
                    : "TBD"}
                  <br />
                  Time: {humanModel.memorialTimezones || "TBD"}
                  <br />
                  Package:{" "}
                  {humanModel.packageNameAndPrice ||
                    "TBD, ranging from $995 to $2,495"}
                  {humanModel.packageServices && (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <br />
                      Services Included: <br />
                      {humanModel.packageServices}
                    </div>
                  )}
                </div>

                {!signedTerms && (
                  <>
                    {/* <Grid item sm={6}>
                        <label>Check box to confirm</label>
                        <Checkbox
                          checked={!!humanModel.signedContractsTerms[userId]}
                          color="default"
                          onChange={(_) => {
                            let terms = humanModel.signedContractsTerms || {};
                            terms[userId] = terms[userId] ? null : new Date();
                            setHumanModel({
                              ...humanModel,
                              signedContractsTerms: terms,
                            });
                          }}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      </Grid> */}
                    <br />
                    <SaveIndicator
                      hideStatus
                      onClickSave={(event: any) => {
                        event.preventDefault();
                        let terms = humanModel.signedContractsTerms || {};
                        terms[userId] = terms[userId] ? null : new Date();
                        setHumanModel({
                          ...humanModel,
                          signedContractsTerms: terms,
                        });
                        updateFirestore({
                          signedContractsTerms: terms,
                        }).then(checkSigning);
                      }}
                      textOverride="Confirm"
                      saveStatus={saveStatus}
                    ></SaveIndicator>
                    <br />
                    <Typography>
                      {" "}
                      By confirming, you agree to our{" "}
                      <Link target="_blank" href={Routes.CONTRACT_TERMS}>
                        Terms & Conditions
                      </Link>
                      .
                    </Typography>
                  </>
                )}
                {signedTerms && (
                  <Typography>
                    <br />
                    Thanks for confirming the service details. Our team will be
                    in touch with you soon.
                    <br />
                    <br />
                    If you have questions in the meantime, please email us at{" "}
                    <Link target="_blank" href="mailto:planning@afterword.com">
                      planning@afterword.com
                    </Link>
                  </Typography>
                )}
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default HumanConfirm;
