import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AuthRoutes } from "../views/Auth";
import AdminRoute from "../AdminRoute";
import UserPrivateRoute from "../UserPrivateRoute";
import { DashboardRoutes } from "../views/Dashboard";
import { ProgramRoutes } from "../views/Program";
import { FamilyDashboardRoutes } from "../views/FamilyDashboard";
import { Routes } from "./constants";
import { TOS } from "../views/FamilyDashboard/components";
// import { Dashboard } from "../views/Dashboard/components";

const ApplicationRoutes = () => {
  return (
    <Router>
      <Switch>
        <AdminRoute path={Routes.ADMIN_HOME} component={DashboardRoutes} />

        <UserPrivateRoute
          path={Routes.HOME_PLANNER}
          component={FamilyDashboardRoutes}
        />
        <UserPrivateRoute path={Routes.TOS} component={TOS} />
        <UserPrivateRoute
          path={Routes.CONTRACT_TERMS}
          component={FamilyDashboardRoutes}
        />
        <Route path="/program" component={ProgramRoutes} />

        {/* <Route path="/" component={HomeRoutes} /> */}

        {/* <Route path={Routes.SIGNUP} component={AuthRoutes} /> */}
        <Route path={Routes.LOGIN} component={AuthRoutes} />
        <Route path={Routes.VERIFY_EMAIL} component={AuthRoutes} />
        <Route path={Routes.EMAIL_ME_A_LINK} component={AuthRoutes} />

        <Redirect
          to={Routes.ADMIN_HUMANS + "/:humanId"}
          from="/humans/:humanId"
        />
        <Redirect to={Routes.ADMIN_HUMANS} from="/humans" />
        <Redirect to={Routes.HOME_PLANNER} from="/" />
      </Switch>
    </Router>
  );
};

export default ApplicationRoutes;
