import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import uuid from "react-uuid";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
// import Typography from '@material-ui/core/Typography';
// import Card from '@material-ui/core/Card';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Input } from "@material-ui/core";

import { HumanTimeline } from "../components/humanTimeline";

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  })
);

export enum SaveStatus {
  NEW = "new",
  LOADING = "loading",
  LOADED = "loaded",
  SAVING = "saving",
  SAVED = "saved",
  SAVING_ERROR = "saving_error",
}

const HumanMedia = ({ humanId, viewOnly }: any) => {
  const [dropboxUrl, setDropboxUrl] = useState("");
  const [carouselUrls, setCarouselUrls] = useState([]);

  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [saveStatus, setSaveStatus] = useState(SaveStatus.NEW);

  const fetchAndSetHuman = async () => {
    setSaveStatus(SaveStatus.LOADING);
    const humanData = await db.collection("humans").doc(String(humanId)).get();
    if (!humanData.exists) {
      // creating new human entity
      console.log("No such document!");
      return;
    } else {
      let human: any = humanData.data();

      // Strings
      setDropboxUrl(human.dropboxUrl || "");

      // Objects
      setCarouselUrls(human.carouselUrls || []);

      // Dates
      setLastModifiedDate(timestampToDate(human.lastModifiedDate));
    }
  };

  const timestampToDate = (ts: firebase.firestore.Timestamp) => {
    return !ts ? "" : new Date(ts.seconds * 1000).toISOString().substr(0, 10);
  };

  const handleMultiImageUpload = async (event: any) => {
    event.preventDefault();
    const imagesList = event.target.files;
    return Object.entries(imagesList).map(async (image: any) => {
      // image[0] = key, image[1] = file
      return await saveImageToFirebase(image[1]);
    });
  };

  const saveImageToFirebase = async (image: any) => {
    var newMetadata = {
      cacheControl: "public,max-age=4000",
    };
    const fileRef = storageRef.child(`humans/${humanId}/` + uuid());
    await fileRef.put(image, newMetadata);
    let newDownloadURL = await fileRef.getDownloadURL();
    console.log(newDownloadURL);
    return String(newDownloadURL);
  };

  const getHumanFromState = () => {
    let lastModifiedDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date()
    );

    return {
      dropboxUrl: dropboxUrl,
      carouselUrls: carouselUrls,
      lastModifiedDate: lastModifiedDateTimestamp,
    };
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    var newHumanRef = db.collection("humans").doc(String(humanId));

    setSaveStatus(SaveStatus.SAVING);
    newHumanRef
      .update(getHumanFromState())
      .then(() => {
        setSaveStatus(SaveStatus.SAVED);
      })
      .catch((err) => {
        console.log("error", "=>", err);
        setSaveStatus(SaveStatus.SAVING_ERROR);
      });
  };

  useEffect(() => {
    fetchAndSetHuman();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={9}>
            <fieldset disabled={viewOnly}>
              <Card style={{ padding: "40px", marginBottom: "40px" }}>
                <CardHeader
                  title="Memorial Images"
                  style={{ padding: "0px" }}
                />
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "20px", paddingTop: "20px" }}
                >
                  {/* Dropbox URL */}
                  <Grid item xs={12} className="humanFormItem">
                    <TextField
                      id="outlined-basic"
                      label="Dropbox URL"
                      variant="filled"
                      className="block"
                      value={dropboxUrl}
                      onChange={(event) => setDropboxUrl(event.target.value)}
                      fullWidth={true}
                      style={{ backgroundColor: "white" }}
                    />
                  </Grid>

                  {/* Carousel URLs */}
                  <Grid item xs={12} className="humanFormItem">
                    <label>Upload Multiple Images</label>
                    <input
                      type="file"
                      multiple
                      className="block"
                      onChange={(event) => {
                        handleMultiImageUpload(event).then(
                          (allPromises: any) => {
                            Promise.all(allPromises).then(
                              async (images: any) => {
                                let imageList = [...carouselUrls].concat(
                                  images
                                );
                                setCarouselUrls(imageList);
                                console.log(imageList);
                                await db
                                  .collection("humans")
                                  .doc(humanId)
                                  .update({
                                    carouselUrls:
                                      firebase.firestore.FieldValue.arrayUnion(
                                        ...imageList
                                      ),
                                  });
                              }
                            );
                          }
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm container spacing={2}>
                    {carouselUrls &&
                      carouselUrls.map((imageUrl, idx) => {
                        return (
                          <Grid item xs={4}>
                            <a href={imageUrl} target="_blank">
                              <img
                                src={imageUrl}
                                style={{ maxWidth: "200px" }}
                              />
                            </a>
                            <button
                              onClick={async (_) => {
                                let shouldDelete =
                                  window.confirm("Are you sure?");
                                if (!shouldDelete) {
                                  return;
                                }
                                let newUrls = carouselUrls.concat([]);
                                newUrls.splice(idx, 1);
                                setCarouselUrls(newUrls);
                                // TODO: also delete in storage
                                let result = await db
                                  .collection("humans")
                                  .doc(humanId)
                                  .update({
                                    carouselUrls:
                                      firebase.firestore.FieldValue.arrayRemove(
                                        imageUrl
                                      ),
                                  });
                              }}
                            >
                              remove
                            </button>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Card>
            </fieldset>
          </Grid>
          <Grid item xs={3}>
            <div className="save-container" style={{ textAlign: "center" }}>
              <Button color="primary" variant="contained" type="submit">
                Save
              </Button>
              <div className="text-gray-500 text-md">{saveStatus}</div>
              {saveStatus === SaveStatus.SAVING_ERROR && (
                <div className="text-red-500 text-lg">Error: check console</div>
              )}
            </div>
            <HumanTimeline />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default HumanMedia;
