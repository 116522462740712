export const Routes = {
  LOGIN: "/login",
  VERIFY_EMAIL: "/login/verify-email",
  EMAIL_ME_A_LINK: "/login/email-link",
  SIGNUP: "/signup",
  TOS: "/profile/agree",
  PROFILE: "/profile",
  MEMORIALS: "/m",

  CONTRACT_TERMS: "/terms-and-conditions",

  HOME: "/",
  HOME_PLANNER: "/home",
  HOME_FUNERAL_DIRECTOR: "/h",
  HOME_VIDEOGRAPHER: "/v",

  ADMIN_HOME: "/admin",
  ADMIN_HUMANS: "/admin/humans",
  ADMIN_USERS: "/admin/users",
  ADMIN_OAUTH: "/admin/oauth",
  ADMIN_PROGRAM: "/program",
};

export const MemorialRoles = {
  FAMILY: 0,
  FUNERAL_DIRECTOR: 1,
  VIDEOGRAPHER: 2,
};

export const UserRoles = {
  FAMILY: "family",
  FUNERAL_DIRECTOR: "director",
  VIDEOGRAPHER: "videographer",
  ADMIN: "admin",
};

export const PageVariants = {
  DEFAULT: "default",
  PAYMENT_OVERDUE: "paymentOverdue",
  SPLIT: "split",
};
