import React from "react";
import { Route, Switch } from "react-router-dom";
import { FamilyDashboard, TOS, ContractTerms } from "../components";
import UserPrivateRoute from "../../../UserPrivateRoute";
import { Routes } from "../../../config/constants";

export const FamilyDashboardRoutes = () => {
  return (
    <Switch>
      <UserPrivateRoute exact path="/" component={FamilyDashboard} />
      <UserPrivateRoute exact path="/home" component={FamilyDashboard} />
      <UserPrivateRoute
        exact
        path={Routes.CONTRACT_TERMS}
        component={ContractTerms}
      />
      <Route exact path={Routes.TOS} component={TOS} />
    </Switch>
  );
};
