import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { Routes } from "./config/constants";

const UserPrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { authenticated, userRoles, isAdmin, tosSigned, loadingAuthState } =
    useContext(AuthContext);

  if (loadingAuthState) {
    return (
      <div style={{ textAlign: "center" }}>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        // if !auth, redirect
        // if no userroles, isadmin, admin
        // if userroles, and admin, defer to user roles
        authenticated ? (
          // Update date of terms of service here
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.LOGIN,
              state: { prevPath: rest.path },
            }}
          />
        )
      }
    />
  );
};

export default UserPrivateRoute;
