import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { Humans, HumanInfo, ZoomOauth } from "../components";
import AdminRoute from "../../../AdminRoute";
import { Routes } from "../../../config/constants";

export const HumansRoutes = () => {
  return (
    <Switch>
      <AdminRoute exact path={Routes.ADMIN_HUMANS} component={Humans} />
      <AdminRoute
        exact
        path={Routes.ADMIN_HUMANS + "/:humanId"}
        component={HumanInfo}
      />
      <AdminRoute
        exact
        path={Routes.ADMIN_HUMANS + "/:humanId/:tabName"}
        component={HumanInfo}
      />
      <AdminRoute exact path={Routes.ADMIN_OAUTH} component={ZoomOauth} />
    </Switch>
  );
};
